import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RestService } from '../services/rest.service';
import { EnvService } from './env.service';
@Injectable({
  providedIn: 'root'
})
export class CcadminHelperService {
  HasAddress = false;
  HasBoundary = false;
  UseExternalDb = false;
  IsSuperAdmin = false;
  AddressSchema = null;
  IngestSchema = null;
  BoundarySchema = null;
  AgencyList = null;
  AddressTenant;
  BoundaryTenant;
  ExternalTenant;
  constructor(private rest: RestService,
              private env: EnvService
  ) { }
  public CCAdminVersion = this.env.admin;
  private env1 = this.env.env1;
  public GetMeCCAdmin(): any {
    this.rest.getMe().subscribe(
      (res) => {
        return res;
      });
  }
  public SameTenants(): boolean {
    return this.GetAddressTenant() === this.GetBoundaryTenant();
  }

  public async GetTenants() {
    if (this.HasAddress) {
    await this.rest.gettenantId('Address').toPromise().then(res => {
      this.AddressTenant = res;
    }).catch((error) => {
      console.log(JSON.stringify(error));
    });
  }
    if (this.HasBoundary) {
      await this.rest.gettenantId('Boundary').toPromise().then(res => {
      this.BoundaryTenant = res;
    }).catch((error) => {
      console.log(JSON.stringify(error));
    });
  }
    if (this.UseExternalDb) {
    await this.rest.gettenantId('External').toPromise().then(res => {
      this.ExternalTenant = res;
    }).catch((error) => {
      console.log(JSON.stringify(error));
    });
  }
    return true;
  }

  public IsExternalIngest(): boolean {
    if (this.CCAdminVersion === 'admin2.') {
      return this.IngestSchema.serviceConfiguration.IsExternalCustomer;

    }
    return this.IngestSchema.configuration.IsExternalCustomer;
  }
  public GetAddressTenant(): any {
    return this.AddressTenant;
  }
  public GetIngestTenant(): any {
    return this.ExternalTenant;
  }
  public GetBoundaryTenant(): any {
    return this.BoundaryTenant;

  }
  public GetCCAdminAgencyList(): void {
    this.rest.getallagencies().subscribe(
      (res) => {        
        this.AgencyList = res['_embedded']['item'];
      }

    );
  }
  public CCAdminIsImpersonating(getMeReturn): void {
    if (getMeReturn.impersonatingUser !== undefined) {
      this.IsSuperAdmin = true;
    }
    else {
      this.IsSuperAdmin = false;
    }
  }
  public ParseServices(APIReturn: object): void {
    if (this.CCAdminVersion === 'admin2.') {
      let BoundaryName;
      let AddressName;
      let IngestName;
      const cloudversion = sessionStorage.getItem('cloud');
      if (this.env1 === '.dev.') {
        AddressName = 'GeoVerificationDev';
        BoundaryName = 'GisBoundaryConfigDev';
        IngestName = 'GeoDataIngestDev';
      }
      else if (this.env1 === '.usgov-stage.' || cloudversion === 'gov') {
        BoundaryName = 'GisBoundaryConfigUSGov';
        AddressName = 'GeoVerification';
        IngestName = 'GeoDataIngestUSGov';
      }
      else {
        BoundaryName = 'GisBoundaryConfig';
        AddressName = 'GeoVerification';
        IngestName = 'GeoDataIngest';
      }
      const Services = APIReturn['_embedded']['agencyServiceConfigurations'];
      for (let index = 0; index < Object.keys(Services).length; index++) {
        const systemName = Services[index].systemName;
        switch (Services[index].systemName) {
          case AddressName:
            this.HasAddress = true;
            this.AddressSchema = Services[index];
            break;
          case IngestName:
            this.IngestSchema = Services[index];
            break;
          case BoundaryName:
            this.HasBoundary = true;
            this.BoundarySchema = Services[index];
            break;
          default:
            break;
        }
      }
    }
    else {
      let BoundaryName;
      let AddressName;
      let IngestName;
      const cloudversion = sessionStorage.getItem('cloud');
      if (this.env1 === '.dev.') {
        AddressName = 'Geo Verification Dev';
        BoundaryName = 'GIS Boundary Dev';
        IngestName = 'Geodatabase Ingest Dev';
      }
      else if (this.env1 === '.usgov-stage.'  || cloudversion === 'gov') {
        AddressName = 'Geo Verification';
        BoundaryName = 'GIS Boundary USGov';
        IngestName = 'Geodatabase Ingest USGov';
      }
      else {
        AddressName = 'Geo Verification';
        BoundaryName = 'GIS Boundary';
        IngestName = 'Geodatabase Ingest';
       }
      const Services = APIReturn['_embedded']['item'];
      for (let index = 0; index < Object.keys(Services).length; index++) {
        const serviceTitle = Services[index]._links.service.title;
        if (serviceTitle === AddressName) {
          this.HasAddress = true;
          this.AddressSchema = Services[index];

        } else if (serviceTitle === IngestName) {
          this.IngestSchema = Services[index];
        } else if (serviceTitle === BoundaryName) {
          this.BoundarySchema = Services[index];
          this.HasBoundary = true;
        }
      }
    }
  }



}
