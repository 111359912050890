// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#filterBeats {
  height: 10px;
  width: 250px;
  float: left;
  position: relative;
  bottom: 5px;
}

#navigate-draw {
  float: left;
  height: 58px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

#navigate-draw-icon {
  height: 50px;
  width: 50px;
  position: relative;
  right: 10px;
}

#Boundary_units {
  display: inline-block;
  width: 100px;
  position: relative;
  left: 16px;
}

#MatchDistance {
  display: inline-block;
  position: relative;
}

#distanceTag {
  display: inline-block;
}

#distanceGroup {
  margin-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/boundary/boundary.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,+BAAA;EACA,4BAAA;EACA,gCAAA;EACA,6BAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,qBAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;AACF;;AACA;EACE,qBAAA;EACA,kBAAA;AAEF;;AAAA;EACE,qBAAA;AAGF;;AADA;EACE,gBAAA;AAIF","sourcesContent":["#filterBeats {\n  height: 10px;\n  width: 250px;\n  float: left;\n  position: relative;\n  bottom: 5px;\n}\n\n#navigate-draw {\n  float: left;\n  height: 58px;\n  width: 180px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-bottom-left-radius: 10px;\n  border-top-left-radius: 10px;\n  border-bottom-right-radius: 10px;\n  border-top-right-radius: 10px;\n}\n\n#navigate-draw-icon {\n  height: 50px;\n  width: 50px;\n  position: relative;\n  right: 10px;\n}\n\n#Boundary_units {\n  display: inline-block;\n  width: 100px;\n  position: relative;\n  left: 16px;\n}\n#MatchDistance {\n  display: inline-block;\n  position: relative;\n}\n#distanceTag {\n  display: inline-block;\n}\n#distanceGroup {\n  margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
