import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router,
  CanLoad,
  Route,
  UrlSegment } from '@angular/router';
import { TokenService } from './token.service';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { PrivilegesService } from './privileges.service';
import {AuthenticationService} from '@msi/commandcentral-user-authentication';
import { CanActivateChild } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(private tokenService: TokenService,
              private router: Router,
              private privilegesService: PrivilegesService,
              private authenticationService: AuthenticationService) {
  }
   customerID;
   agencyId;
   admin;
   cloud;
   private routerPath: string[];
  private targetPath: string;

   private setRouterPath(route: ActivatedRouteSnapshot): void {
    const keyString = '_urlSegment';
    this.routerPath =
      route && route[keyString] && route[keyString].segments
        ? [...route[keyString].segments.map(url => url.path)]
        : undefined;
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.customerID = route.queryParams.customerId;
    this.agencyId = route.queryParams.agencyId;
    sessionStorage.setItem('customerID', this.customerID);
    this.tokenService.setAdminUrl(route.queryParams.admin);
    this.admin = route.queryParams.admin;
    this.cloud = route.queryParams.cloud;
    if (state.url.includes('/boundary/gov') || state.url.includes('/geodatabase/gov')){// this.cloud === undefined) {
      this.cloud = 'gov'; // sessionStorage.getItem('cloud');
      sessionStorage.setItem('cloud', this.cloud);
    }
    else
    {
      sessionStorage.removeItem('cloud');
    }
    sessionStorage.setItem('admin', this.admin);
    this.setRouterPath(route);
    return this.isAuthenticated();
  }

  public async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.isAuthenticated();
  }

  public async isAuthenticated(): Promise<boolean> {
    const savedToken = await this.authenticationService.authenticate();
    return !!savedToken;
  }

  }
