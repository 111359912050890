import { Component } from '@angular/core';

@Component({
  selector: 'app-access-error',
  templateUrl: './access-error.component.html',
  styleUrls: ['./access-error.component.css']
})
export class AccessErrorComponent {
  constructor() { }
}
