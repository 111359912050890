import { Component, Host, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { CaseService, FileUploadComponent, FileUploadService } from '@msi/cobalt';
import { FormControl } from '@angular/forms';
import { UploadTypeHelperService } from './upload-type-helper.service';
@Component({
  selector: 'app-upload-footer',
  templateUrl: './upload-footer.component.html'})
class UploadFooterComponent implements OnInit, OnDestroy {
  value: string | null = 'File Type';
  ctrl = new FormControl();
  options: string[];


  private editingSubscription: (...args: any[]) => void;
  private uploadingSubscription: (...args: any[]) => void;
  private reselectAssetsSubscription: (...args: any[]) => void;
  private additionalDataSubscription: (...args: any[]) => void;

  @Input()
  caseId: string;

  editing = false;
  uploading = false;
  reselectAssets = false;
  additionalData: any;

  constructor(
    private fileUploadService: FileUploadService,
    private footerhelperService: UploadTypeHelperService,
    @Optional() @Host() public fileUploadComponent: FileUploadComponent
  ) {}

  ngOnInit() {
    this.ctrl.valueChanges.subscribe(x => {
      this.footerhelperService.setuploadtype(x);
    });
    if (!this.caseId && this.fileUploadComponent) {
      this.caseId = this.fileUploadComponent.caseId;
    }
    this.options = ['Address', 'Boundary'];

    const caseService: CaseService = this.fileUploadService.get(this.caseId);

    this.editingSubscription = (editing: boolean) => (this.editing = editing);
    this.uploadingSubscription = (uploading: boolean) => (this.uploading = uploading);
    this.reselectAssetsSubscription = (reselectAssets: boolean) => (this.reselectAssets = reselectAssets);
    this.additionalDataSubscription = (additionalData: any) => (this.additionalData = additionalData);

    caseService.on('editing', this.editingSubscription);
    caseService.on('uploading', this.uploadingSubscription);
    caseService.on('reselectAssets', this.reselectAssetsSubscription);
    caseService.on('additionalData', this.additionalDataSubscription);
  }

  ngOnDestroy() {
    const caseService: CaseService = this.fileUploadService.get(this.caseId);

    caseService.off('editing', this.editingSubscription);
    caseService.off('uploading', this.uploadingSubscription);
    caseService.off('reselectAssets', this.reselectAssetsSubscription);
    caseService.off('additionalData', this.additionalDataSubscription);
  }

  onAddMore(e: Event, name: string) {
    e.preventDefault();
    e.stopPropagation();

    this.fileUploadService.get(this.caseId).emit(name);
  }

  onUpload(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    const caseService: CaseService = this.fileUploadService.get(this.caseId);

    if (this.additionalData === null) {
      this.fileUploadService.get(this.caseId).callMethod('upload');
    } else {
      const validate = caseService.getAdditionalDataValidate();

      try {
        validate(this.additionalData);
      } catch (err) {
        caseService.setProperty('dataErrors', err);

        return;
      }
    }

    this.fileUploadService.get(this.caseId).callMethod('upload');
  }

}

export {
  UploadFooterComponent
};

