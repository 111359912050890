import { Injectable } from '@angular/core';
import { from, OperatorFunction, Subject, Observable } from 'rxjs';
import { map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { BlobItemUpload, ContainerDatasetRequest } from '../types/azure-storage';
import { BlobSharedViewStateService } from './blob-shared-view-state.service';
import { BlobStorageService } from './blob-storage.service';
import { AnonymousCredential, ContainerClient } from '@azure/storage-blob';
import { AssetFile } from '@msi/cobalt';

@Injectable({
  providedIn: 'root'
})
export class BlobUploadsViewStateService {
  private uploadQueueInner$ = new Subject<AssetFile[]>();
  private sStatus = new Subject<string>();

  uploadedItems$ = this.uploadQueue$.pipe(

    mergeMap(file => this.uploadFile(file)),
    this.blobState.scanEntries()
  );

  uploadStatus = this.getUploadStatus();

  folderName = this.getFolderName;

  setUploadStatus(message: string)  {
    this.sStatus.next(message);
  }

  getUploadStatus(): Observable<string> {
    return this.sStatus.asObservable();
}

  get getFolderName() {
    const date = new Date(Date.now());
    return `data-${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
  }

  get uploadQueue$() {
    return this.uploadQueueInner$
      .asObservable()
      .pipe(mergeMap(files => from(files)));
  }

  constructor(
    private blobStorage: BlobStorageService,
    public blobState: BlobSharedViewStateService
  ) { }

  uploadItems(files: AssetFile[]): void {
    this.uploadQueueInner$.next(files);
  }



  private getContainerClient(sasToken: string) {
    const anonymousCredential = new AnonymousCredential();
    const client = new ContainerClient(sasToken, anonymousCredential);
    return client;
  }

  private uploadFile = (file: AssetFile) =>
    this.blobState.getStorageOptionsWithDataset().pipe(
      switchMap(options =>
        this.blobStorage.uploadFile(file, this.folderName, this.blobStorage.buildClient(options))
        .pipe(
          this.mapUploadResponse(file.file, options),
          this.blobState.finaliseBlobChange(options.datasetName)
        )
      )
  )

  private mapUploadResponse = (
      file: File,
      options: ContainerDatasetRequest
    ): OperatorFunction<number, BlobItemUpload> => source =>
    source.pipe(
      map(progress => ({
        filename: file.name,
        datasetName: this.folderName,
        progress: parseInt(((progress / file.size) * 100).toString(), 10)
      })),
      startWith({
        filename: file.name,
        datasetName: this.folderName,
        progress: 0
      })
    )
}

