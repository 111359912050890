import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RestService } from '../services/rest.service';
import { EnvService } from '../services/env.service';
import { ToastService } from '@msi/cobalt';
import { HttpErrorResponse } from '@angular/common/http';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

@Component({
  selector: 'app-msi-config',
  templateUrl: './msi-config.component.html',
  styleUrls: ['./msi-config.component.scss'],
})
export class MsiConfigComponent implements OnInit, AfterContentChecked {
  constructor(public rest: RestService, private env: EnvService, private Toast: ToastService, private router: Router, private cdref: ChangeDetectorRef) {}
  Service = new FormControl();
  TenantId = '';
  custTenantId = '';
  cid = new FormControl();
  custIdselect = new FormControl();
  Serviceoptions: string[];
  customerIds: string[];
  TenantIdForm = new FormControl();
  GeocoderType = new FormControl('');
  UtahApiKeyForm = new FormControl('');
  UtahApiReferrerForm = new FormControl('');
  UseTenantSize = true;
  ShowDrawPolygons = false;
  ShowEditPremiseHazards = false;
  customerIddropdown;
  boundaryTenantLoader;
  tenantSize;
  isExist;
  UseExistingTenant = false;
  CustomersTenantExists = false;
  IsSuperAdmin = false;
  customerGeocoderType = null;
  routeCustomerId;
  routeCustomerExists = false;
  firstLoad = true;
  ServiceEnabledForCustomer = false;
  ShowEditMode = false;
  P1Message = '';
  ServiceName = {
    'Premise Hazards': 'phzProvisioning',
    'Premise Hazards Dev': 'phzProvisioningDev',
    'Geocoding Service': 'GeoVerification',
    'Geocoding Service Dev': 'GeoVerificationDev',
    'P1 External Ingest': 'GeoDataIngest',
    'P1 External Ingest USGov-Stage': 'GeoDataIngestUSGov',
    'P1 External Ingest Dev': 'GeoDataIngestDev',
    'GIS Boundary': 'GisBoundaryConfig',
    'GIS Boundary Dev': 'GisBoundaryConfigDev',
    'GIS Boundary USGov-Stage': 'GisBoundaryConfigUSGov',
  };
  DBServiceName = {
    'Premise Hazards': 'PremiseHazards',
    'Premise Hazards Dev': 'PremiseHazards',
    'Geocoding Service': 'Address',
    'Geocoding Service Dev': 'Address',
    'P1 External Ingest': 'External',
    'P1 External Ingest Dev': 'External',
    'P1 External Ingest USGov-Stage': 'External',
    'GIS Boundary': 'Boundary',
    'GIS Boundary Dev': 'Boundary',
    'GIS Boundary USGov-Stage': 'Boundary',
  };
  sizeData = {
    Small: 'S',
    Medium: 'M',
    Large: 'L',
  };

  geocoders = ['ESRIOnline', 'MSIGeocoder', 'UtahGeocoder', 'CustomEsriLocator'];
  sizeselect = new FormControl();
  DrawPolygonsForm = new FormControl(false);
  ShareWithOthers = new FormControl(false);
  EditPremiseHazardsForm = new FormControl(false);
  rootAgencyConfiguration;
  buttonSettings = {
    text: 'ACCEPT',
    click: this.UpdateGeoCoder,
    cid: null,
    GeocoderType: null,
    TenantId: '',
    rest: this.rest,
    Toast: this.Toast,
    Service: null,
    tenantSize: null,
    DBServiceName: null,
    sizeselect: null,
    SetUtahGeoCoder: this.SetUtahGeoCoder,
    UtahApiKeyForm: this.UtahApiKeyForm,
    UtahApiReferrerForm: this.UtahApiReferrerForm,
    DrawPolygonsForm: this.DrawPolygonsForm,
    EditPremiseHazardsForm: this.EditPremiseHazardsForm,
    ShareWithOthers: this.ShareWithOthers,
    routeCustomerId: null
  };
  objectKeys = Object.keys;
  filteredOptions$ = this.cid.valueChanges.pipe(
    startWith(''),
    map((value) => this._filterOptions(value))
  );
  isEmpty = this.filteredOptions$.pipe(map((a) => a.length === 0));
  private _filterOptions(filterValue: string): string[] {
    this.SetTenantId();
    if (this.customerIds && filterValue) {
      return this.customerIds.filter((opt) => opt.toLowerCase().includes(filterValue.toLowerCase()));
    } else {
      return this.customerIds;
    }
  }
  IsValidCustomer(): boolean {
    const customerID = this.routeCustomerId !== null ? this.routeCustomerId : this.cid.value;
    return this.customerIds !== undefined ? this.customerIds.includes(customerID) : false;
  }
  SetTenantId(): void {
     this.rest.customerID = this.routeCustomerId !== null ? this.routeCustomerId : this.cid.value;
    if (this.Service.value && this.rest.customerID && this.customerIds.includes(this.rest.customerID)) {
      const DBName = this.DBServiceName[this.Service.value];
      this.SetEnv();
      const CustomerId = this.rest.customerID;
      const normalizedTenantId = this.rest.customerID.toLowerCase().split('.').join('-');
      this.TenantId = normalizedTenantId;
      this.rest.getCustomerTenantId(CustomerId, DBName).subscribe(
        (x) => {
          this.TenantId = x;
          this.CustomersTenantExists = true;
          if (DBName === 'Boundary') {
            this.getBoundaryTenantSize();
          } else if (DBName === 'PremiseHazards') {
            this.getPHZTenantSize();
          }
          if(this.firstLoad)
          {
            this.rest
              .getCustomerListByTenant(this.DBServiceName[this.Service.value], this.TenantId)
              .toPromise()
              .then(async (success) => {
                var data = success.toString().split(',');
                if(data.length > 1)       
                {
                  this.custIdselect.setValue(success[0]);                
                  this.ShareWithOthers.setValue(true);
                  this.UseExisting();            
                  this.firstLoad = false;         
                }        
              },
              (err) => {
                console.log(err);        
                this.firstLoad = false; 
              });  
          }
        },
        (e: HttpErrorResponse) => {
          if (e.status === 404) {
            this.CustomersTenantExists = false;
            this.Toast.warning(`Customer does not have a stored TenantId, see suggested TenantId`);
            if (DBName === 'PremiseHazards') {
              this.TenantId = this.rest.customerID;
              this.TenantIdForm.setValue(this.TenantId);
              this.ShowEditPremiseHazards = true;
            } else {
              this.TenantId = this.rest.customerID;
              const re = /\./gi;
              this.TenantId = this.TenantId.toLowerCase().replace(re, '-');
              if (DBName === 'External') {
                this.TenantId += '-external';
              }
            }
          } else {
            this.Toast.error(`Failed to get Customer TenantId.`);
          }
        }
      );
      if (this.Service.value === 'Geocoding Service' || this.Service.value === 'Geocoding Service Dev') {
        this.getCustomerGeocoderType(CustomerId);
      }
    }
  }

  SetEnv() {
    if (this.env.env !== '.usgov.' && !this.env.env.includes('.sec.') && this.env.env !== '.') {
      if (this.ServiceName[this.Service.value].includes('Dev')) {
        this.rest.geobaseURL = this.rest.geobaseURL.replace(this.rest.env1, '.dev.');
        this.rest.env1 = '.dev.';
      } else if (
        this.ServiceName[this.Service.value].includes('Gov')
      ) {
        this.rest.geobaseURL = this.rest.geobaseURL.replace(this.rest.env1, '.usgov-stage.');
        this.rest.env1 = '.usgov-stage.';
      } else {
        this.rest.geobaseURL = this.rest.geobaseURL.replace(this.rest.env1, '.stage.');
        this.rest.env1 = '.stage.';
      }
    }
  }
  getPHZTenantSize() {
    this.rest
      .getPHZTenatSize(this.TenantId)
      .toPromise()
      .then(
        (res) => {
          this.isExist = true;
          let tenantInfo: any;
          tenantInfo = res;
          this.sizeselect.setValue(tenantInfo.tenantSize);
          this.EditPremiseHazardsForm.setValue(tenantInfo.phzEditEnabled);
          this.ShowEditPremiseHazards = true;
        },
        (err) => {
          console.log(err);
          this.Toast.error('Unable to load TenantSize!');
        }
      );
  }
  getBoundaryTenantSize() {
    this.boundaryTenantLoader = this.rest.getTenantSize(this.TenantId).subscribe(
      (res) => {
        let tenantInfo: any;
        tenantInfo = res;
        this.isExist = true;
        this.sizeselect.setValue(tenantInfo.tenantSize);
        this.tenantSize = tenantInfo.tenantSize;
        this.DrawPolygonsForm.setValue(tenantInfo.drawPolygons);
        this.ShowDrawPolygons = true;
      },
      (error) => {
        console.log(error);
        this.Toast.error('Unable to get Tenant Size');
      }
    );
  }
  populateDropdown(): void {
    const ccadminEnviornment = this.env.env;
    switch (ccadminEnviornment) {
      case '.usgov.':
      case '.sec.':
      case '-preprod.sec.':
      case '.':
        this.Serviceoptions = ['Premise Hazards', 'Geocoding Service', 'P1 External Ingest', 'GIS Boundary'];
        break;
      case '.pi.':
      default:
        this.Serviceoptions = [
          'Premise Hazards',
          'Premise Hazards Dev',
          'Geocoding Service',
          'Geocoding Service Dev',
          'P1 External Ingest',
          'P1 External Ingest Dev',
          `P1 External Ingest USGov-Stage`,
          'GIS Boundary',
          'GIS Boundary Dev',
          'GIS Boundary USGov-Stage',
        ];

        break;
    }

    this.Serviceoptions.sort();
  }
  async ngOnInit(): Promise<void> {
    this.rest.getMe2().subscribe((x) => {
      if (x.me.agency.id === 'motorola-solutions' || x.me.currentAgency === 'motorola-solutions') {
        this.IsSuperAdmin = true;
      }
    });
    this.routeCustomerId = this.router.routerState.root.snapshot.queryParamMap.get('selectedCustomerId');
    this.routeCustomerExists = (this.routeCustomerId !== null);
    this.populateDropdown();
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  UseExisting(): void {
    if(this.ShareWithOthers.value == true)
    {      
      if(!this.firstLoad)
      {
        const warninglabel =
        '​Data Privacy Warning! Using another customer\'s TenantId' +
        'should only be allowed for agencies of the same customer, customers in both agencies will have access to each others data!';
        this.Toast.warning(warninglabel);
        this.custIdselect.reset();
      }
      document.getElementById('other_customers_tenant').style.display = 'block';
      this.UseExistingTenant = true;
      this.ShowEditMode = false;
    }
    else
    {
      document.getElementById('other_customers_tenant').style.display = 'none';
      this.UseExistingTenant = false;
      this.ShowEditMode = (this.Service.value.includes('Ingest'));
    }
  }
  UseNew(): void {
    document.getElementById('uniqueTenant').style.display = 'block';
    //document.getElementById('other_customers_tenant').style.display = 'none';
    this.UseExistingTenant = false;
  }
  async SaveExternalTenant() {
    const customerID = this.routeCustomerId !== null ? this.routeCustomerId : this.cid.value;
    const externalbody = {
      CustomerId: customerID,
      isExistingTenant: true,
      TenantId: this.TenantId,
      serviceName: 'External',
    };
    if (!this.CustomersTenantExists) {
      await this.rest
        .post_addcustomertenant(externalbody)
        .toPromise()
        .then(
          (res) => {
            this.CustomersTenantExists = true;
            this.Toast.success(`Customer TenantId set as ${this.TenantId}.`);
          },
          (e: HttpErrorResponse) => {
            this.Toast.error(`Failed to save external tenant due to error: ${e.error}`);
          }
        );
    } else {
      await this.rest
        .post_updatecustomertenant(externalbody)
        .toPromise()
        .then(
          (res) => {
            this.CustomersTenantExists = true;
            this.Toast.success(`Customer TenantId set as ${this.TenantId}.`);
          },
          (e: HttpErrorResponse) => {
            this.Toast.error(`Failed to save external tenant due to error: ${e.error}`);
          }
        );
    }
  }
  async OnBoardCustomer() {
    if (this.DBServiceName[this.Service.value] === 'External') {
      this.SaveExternalTenant();
    } else if (this.ShareWithOthers.value == true) {
      await this.SyncOnBoardChangesChildCustomer();
    } else {
      const submittedTenantSize = this.sizeselect.value;
      if (this.UseTenantSize && submittedTenantSize === '') {
        this.Toast.error('Select a Tenant Size!');
        return;
      }
      if (this.DBServiceName[this.Service.value] === 'Address') {
        if (this.customerGeocoderType !== null && this.customerGeocoderType !== this.GeocoderType.value) {
          await this.rest
            .getCustomerListByTenant('Address', this.TenantId)
            .toPromise()
            .then(async (success) => {
              const InString = success.toString();
              this.buttonSettings.GeocoderType = this.GeocoderType;
              this.buttonSettings.sizeselect = this.sizeselect;
              this.buttonSettings.Service = this.Service;
              this.buttonSettings.DBServiceName = this.DBServiceName;
              this.buttonSettings.cid = this.cid;
              this.buttonSettings.TenantId = this.TenantId;
              this.buttonSettings.routeCustomerId = this.routeCustomerId;
              this.Toast.warning(
                `WARNING: Updating Customer Geocoder will change Geocoder Type & reset configuration rules for this customer AND all customers with this tenant (${this.TenantId}). Customers affected will be: ${InString}.  Click Accept to apply change`,
                this.buttonSettings
              );
            });
        } else {
          this.SyncOnBoardChanges();
        }
      } else {
        if (!this.isExist) {
          this.SyncOnBoardChanges();
        } else {
          await this.rest
            .getCustomerListByTenant(this.DBServiceName[this.Service.value], this.TenantId)
            .toPromise()
            .then(async (success) => {
              const InString = success.toString();
              const buttonSettings1 = {
                text: 'ACCEPT',
                click: this.UpdateTenantSize,
                rest: this.rest,
                Toast: this.Toast,
                cid: this.cid,
                sizeselect: this.sizeselect,
                Service: this.Service,
                DBServiceName: this.DBServiceName,
                TenantId: this.TenantId,
                DrawPolygonsForm : this.DrawPolygonsForm,
                EditPremiseHazardsForm: this.EditPremiseHazardsForm,
                routeCustomerId: this.routeCustomerId
              };
              this.Toast.warning(
                `WARNING: Updating tenant will take effect for all customers with this tenant (${this.TenantId}). Customers affected will be: ${InString}.  Click Accept to apply change`,
                buttonSettings1
              );
            });
        }
      }
    }
  }
  async UpdateTenantSize() {
    const customerID = this.routeCustomerId !== null ? this.routeCustomerId : this.cid.value;
    if (this.DBServiceName[this.Service.value] === 'PremiseHazards') {
      await this.rest
        .post_tenant_phz(customerID, this.sizeselect.value, this.EditPremiseHazardsForm.value)
        .toPromise()
        .then(
          (success) => {
            this.Toast.success(`Customer Size updated to ${this.sizeselect.value} Successfully.`);
          },
          (e: HttpErrorResponse) => {
            this.Toast.error(`Failed to update size due to error:  ${e.error}.`);
          }
        );
    } else {
      const updateTenatSize = {
        TenantSize: this.sizeselect.value,
        TenantId: this.TenantId,
        DrawPolygons: this.DrawPolygonsForm.value
      };
      await this.rest
        .updateTenantSize(updateTenatSize, this.TenantId)
        .toPromise()
        .then(
          (success) => {
            this.Toast.success(`Tenant updated successfully.`);
            this.tenantSize = this.sizeselect.value;
          },
          (e: HttpErrorResponse) => {
            this.Toast.error(`Failed to update size due to error:  ${e.error}.`);
          }
        );
    }
  }
  async UpdateGeoCoder() {
    const customerID = this.routeCustomerId !== null ? this.routeCustomerId : this.cid.value;
    const GeoverificationConfigModel = {
      CustomerId: customerID,
      GeocoderName: this.GeocoderType.value,
      TenantId: this.TenantId,
    };

    await this.rest
      .setRootAgencyGeocoderConfiguration(GeoverificationConfigModel)
      .toPromise()
      .then(
        (res) => {
          this.Toast.success(`Tenant GeoCoder updated Successfully.`);
          if (this.GeocoderType.value === 'UtahGeocoder') {
            this.SetUtahGeoCoder();
          }
        },
        (e: HttpErrorResponse) => {
          this.Toast.error(`Failed to update geocoder due to error:  ${e.error}.`);
        }
      );
  }
  async SyncOnBoardChangesChildCustomer() {
    const OnBoardingBody = {
      serviceName: this.DBServiceName[this.Service.value],
      ParentCustomerId: this.custIdselect.value,
    };

    this.rest
      .OnBoardCustomerChild(this.rest.customerID, OnBoardingBody)
      .toPromise()
      .then(
        (res) => {
          this.Toast.success(`Customer Onboarded Successfully! `, undefined, {
            autoDismiss: true && 3000,
          });
        },
        (e: HttpErrorResponse) => {
          this.Toast.error(`Failed to onboard due to error: ${e.error}`);
        }
      );
  }
  async SyncOnBoardChanges() {
    const OnBoardingBody = {
      serviceName: this.DBServiceName[this.Service.value],
    };
    if (this.Service.value.includes('Geocoding Service')) {
      OnBoardingBody['geocoder'] = this.GeocoderType.value;
    } else {
      OnBoardingBody['tenantSize'] = this.sizeselect.value;
      if(this.Service.value.includes('GIS Boundary'))
      {
        OnBoardingBody['drawPolygons'] = this.DrawPolygonsForm.value;
      }
      else if(this.Service.value.includes('Premise Hazards'))
      {
        OnBoardingBody['phzEditEnabled'] = this.EditPremiseHazardsForm.value;
      }
    }

    await this.rest
      .OnBoardCustomer(this.rest.customerID, OnBoardingBody)
      .toPromise()
      .then(
        (res) => {
          if (this.Service.value.includes('Geocoding Service') && this.GeocoderType.value === 'UtahGeocoder') {
            this.SetUtahGeoCoder();
          }
          this.Toast.success(`Customer Onboarded Successfully! `, undefined, {
            autoDismiss: true && 3000,
          });
        },
        (e: HttpErrorResponse) => {
          this.Toast.error(`Failed to onboard due to error: ${e.error}`);
        }
      );
  }
  async SetUtahGeoCoder() {
    let DefaultUtahConfig = null;
    await this.rest
      .getGeocoderDefaultConfiguration('UtahGeocoder')
      .toPromise()
      .then(
        (defaultconfig) => {
          DefaultUtahConfig = JSON.parse(defaultconfig);
        },
        (e: HttpErrorResponse) => {
          this.Toast.error(`Failed to get Default Utah Config due to error: ${e.error}`);
        }
      );
    if (DefaultUtahConfig != null) {
      DefaultUtahConfig.UtahApiKey = this.UtahApiKeyForm.value;
      DefaultUtahConfig.UtahApiReferrer = this.UtahApiReferrerForm.value;
      await this.rest
        .setAgencyGeocoderConfiguration(this.rest.customerID, this.rest.customerID, '', DefaultUtahConfig)
        .toPromise()
        .then(
          (res) => {
            this.Toast.success(`Utah rules set successful!`, undefined, {
              autoDismiss: true && 3000,
            });
          },
          (e: HttpErrorResponse) => {
            this.Toast.error(`Failed to set Utah Config due to error: ${e.error}`);
          }
        );
    }
  }
  populateOtherCustomerList(service: string): void {
    this.rest
      .getCustomerList(service)
      .toPromise()
      .then((x) => {
        this.customerIddropdown = x;
        this.customerIddropdown.sort();
      });
  }
  GetOtherCustomersTenantId(): void {
    if (this.Service.value && this.custIdselect.value) {
      const DBName = this.DBServiceName[this.Service.value];
      this.rest.getCustomerTenantId(this.custIdselect.value, DBName).subscribe((tenant) => {
        this.custTenantId = tenant;
      });
    }
  }
  async getServicesCustomers(): Promise<void> {
    let servicearray = [''];
    this.ServiceEnabledForCustomer = false;
    this.firstLoad = true;
    this.ShareWithOthers.setValue(false);
    this.P1Message = '';
    if (this.Service.value) {
      if (this.Service.value.includes('Geocoding Service')) {
        this.UseTenantSize = false;
        this.ShowDrawPolygons = false;
      } else if (this.Service.value.includes('Ingest')) {
        this.UseTenantSize = false;
        this.ShowDrawPolygons = false;
        this.ShowEditMode = true;
      } else {
        this.UseTenantSize = true;
        this.ShowDrawPolygons = this.Service.value.includes('Boundary');
      }
      this.SetEnv();
      const CCAdminName = this.ServiceName[this.Service.value];
      await this.rest
        .GetCCAdminCustomers(CCAdminName)
        .toPromise()
        .then(
          (x) => {
            const CIDList = x as Array<string>;
            servicearray = servicearray.concat(CIDList);
            servicearray.sort();
            this.customerIds = servicearray;            
            this.cid.updateValueAndValidity({ onlySelf: false, emitEvent: true });
            if(!servicearray.includes(this.routeCustomerId))
            {
                this.ServiceEnabledForCustomer = false;                     
                this.Toast.error(`${this.Service.value} has not been added to Customer ${this.routeCustomerId}. This service must be added by an MSI Admin, on the Customer Management tab.`);
            }
            else
            {
              this.ServiceEnabledForCustomer = true;  
              this.populateOtherCustomerList(this.DBServiceName[this.Service.value]);           
                if (this.cid || this.routeCustomerId !== null) {
                  this.SetTenantId();
                  if(this.Service.value.includes('Ingest'))
                  {                  
                    this.P1Message = 'This service should only be used when the Geodatabase Ingest Service will be used to send Address Point and/or Boundary data to a PremiereOne CAD system. When enabling this service, the unique tenant ID must be provided in order to look up the PremiereOne database connection information from the Azure key vault.';
                  }
                }          
            }
          },
          (e: HttpErrorResponse) => {
            this.Toast.error(`Failed to get customer list due to error: ${e.error}.`);
          }
        );
    }
  }
  UpdateBoundaryTenantSize(oldTenantId: string, newTenantId: string, size: string) {
    const tenantSize = size;
    const updateTenatSize = {
      TenantSize: tenantSize,
      TenantId: newTenantId,
    };
    if (tenantSize !== '') {
      this.rest.updateTenantSize(updateTenatSize, oldTenantId).subscribe(
        (res) => {
          this.Toast.success(`Customer Tenant Size updated to ${size}`, undefined, {
            autoDismiss: true && 3000,
          });
          this.Toast.success(`Customer Tenant updated to ${newTenantId}`, undefined, {
            autoDismiss: true && 3000,
          });
          this.TenantId = newTenantId;
          this.tenantSize = size;
        },
        (error) => {
          this.Toast.error(`Failed to update Tenant  ${newTenantId}  Size.`);
          console.log(error);
        }
      );
    } else {
      this.Toast.error(`Please select tenant size.`);
    }
  }

  async getCustomerGeocoderType(customerId) {
    await this.rest
      .getCustomerGeocoderType(customerId)
      .toPromise()
      .then(
        async (success) => {
          this.GeocoderType.setValue(success);
          this.customerGeocoderType = success;
          if (this.customerGeocoderType === 'UtahGeocoder') {
            await this.rest
              .getAgencyConfiguration(customerId, customerId)
              .toPromise()
              .then(
                (a) => {
                  this.rootAgencyConfiguration = JSON.parse(a);
                  this.UtahApiKeyForm.setValue(this.rootAgencyConfiguration.UtahApiKey);
                  this.UtahApiReferrerForm.setValue(this.rootAgencyConfiguration.UtahApiReferrer);
                },
                (err) => {
                  this.Toast.error(
                    `Failed to get customer ${customerId} geocoder type ${this.GeocoderType.value} custom settings: ${err.error}.`
                  );
                }
              );
          } else {
            this.rootAgencyConfiguration = null;
          }
        },
        (e: HttpErrorResponse) => {
          if (e.status !== 404) {
            // this is used for avoiding customer configuration reset by mistake
            this.customerGeocoderType = 'Not Available';
            this.Toast.error(`Failed to get customer geocoder type due to error: ${e.error}.`);
          } else {
            this.GeocoderType.reset();
            this.customerGeocoderType = null;
            this.Toast.warning('Customer does not have a stored Geocoder Type !');
          }
        }
      );
  }

  public async switchGeocoderConfiguration() {
    if (this.customerGeocoderType !== null && this.customerGeocoderType !== this.GeocoderType.value) {
      this.Toast.warning(
        'Please Notice: Changing customer Geocoder will reset all customer agencies to default configuration!'
      );
    }
    if (this.GeocoderType.value === 'UtahGeocoder') {
      if (this.customerGeocoderType === 'UtahGeocoder') {
        const customerID = this.routeCustomerId !== null ? this.routeCustomerId : this.cid.value;
        await this.rest
          .getAgencyConfiguration(customerID, customerID)
          .toPromise()
          .then(
            (a) => {
              this.rootAgencyConfiguration = JSON.parse(a);
              this.UtahApiKeyForm.setValue(this.rootAgencyConfiguration.UtahApiKey);
              this.UtahApiReferrerForm.setValue(this.rootAgencyConfiguration.UtahApiReferrer);
            },
            (err) => {
              this.rootAgencyConfiguration = null;
              this.Toast.error(
                `Failed to get customer ${customerID} geocoder type ${this.GeocoderType.value} custom settings: ${err.error}.`
              );
            }
          );
      } else {
        await this.rest
          .getGeocoderDefaultConfiguration(this.GeocoderType.value)
          .toPromise()
          .then(
            (a) => {
              this.rootAgencyConfiguration = JSON.parse(a);
              this.UtahApiKeyForm.setValue(this.rootAgencyConfiguration.UtahApiKey);
              this.UtahApiReferrerForm.setValue(this.rootAgencyConfiguration.UtahApiReferrer);
            },
            (err) => {
              this.rootAgencyConfiguration = null;
              this.Toast.error(
                `Failed to get geocoder type ${this.GeocoderType.value} default settings: ${err.error}.`
              );
            }
          );
      }
    }
  }
}