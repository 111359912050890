import { Injectable } from '@angular/core';
import { TransferProgressEvent } from '@azure/core-http';
import { Observable, Subscriber, from } from 'rxjs';
import { distinctUntilChanged, scan, startWith } from 'rxjs/operators';
import { PagedAsyncIterableIterator } from '@azure/core-paging';
import { BlobClient, ContainerClient, AnonymousCredential } from '@azure/storage-blob';
import { AbortController } from '@azure/abort-controller';
import { RestService } from './rest.service';
import { BlobStorageRequest, ContainerDatasetRequest } from '../types/azure-storage';
import { AssetFile } from '@msi/cobalt';

@Injectable({
  providedIn: 'root'
})
export class BlobStorageService {
  constructor(
  ) {}

  private  controller = new AbortController();

  getDatasets(request: BlobStorageRequest) {
    const blobServiceClient = this.buildClient(request);
    return this.asyncToObservable(blobServiceClient.listBlobsFlat());
  }

  listItemsInDataset(request: ContainerDatasetRequest) {
    const containerClient = this.buildClient(request);
    return this.asyncToObservable(containerClient.listBlobsByHierarchy(request.datasetName));
  }


  public buildClient(options: BlobStorageRequest) {
    return this.getBlobClient(options.sastoken);
  }

  private getBlobClient(sasToken: string) {
    const anonymousCredential = new AnonymousCredential();
    const client = new ContainerClient(sasToken, anonymousCredential);
    return client;
  }

  public uploadFile(file: AssetFile, folderName: string, client: ContainerClient) {
    return new Observable<number>(observer => {
      const blobClient = client.getBlobClient(`${folderName}/${file.name}`);
      const blockClient = blobClient.getBlockBlobClient();
      const blobTags: Record<string, string> = {
        ContentType: 'IngestDataSet',
        ProcessStatus: 'Unprocessed'
      };
      blockClient.uploadData(file.file,
          ({
            tags: blobTags,
            concurrency: 4,
            onProgress: this.onProgress(observer),
            blobHTTPHeaders: {
              blobContentType: file.file.type
            },
            abortSignal: this.controller.signal,
          }) as object)
        .then(
          this.onUploadComplete(observer, file.file, blobClient),
          this.onUploadError(observer)
        );
    }).pipe(distinctUntilChanged());
  }

  public deleteBlobItem(filename: string, client: ContainerClient) {
    const blobClient = client.getBlobClient(`${filename}`);
    const blockBlobClient = blobClient.getBlockBlobClient();
    return from(blockBlobClient.delete());
  }

  private onUploadError(observer: Subscriber<number>) {
    return (error: any) => observer.error(error);
  }

  private onUploadComplete(observer: Subscriber<number>, file: File, client: BlobClient) {
    return () => {
      client.exists().then((data) => {
        if (data)
        {
          console.log('Check blob exists upon complete ' + file.name);
          observer.next(file.size);
          observer.complete();
        }
        else
        {
          console.log('Blob doesnt exists upon complete. ' + client.name);
        }
      })
      .catch((error) => {
        console.log('Promise rejected with ' + JSON.stringify(error));
        observer.error(error);
      });
    };
  }


  private onProgress(observer: Subscriber<number>) {
    return (progress: TransferProgressEvent) => {
        observer.next(progress.loadedBytes);
    };
  }

  private asyncToObservable<T, TService>(
    iterable: PagedAsyncIterableIterator<T, TService>
  ) {
    return new Observable<T>(
      observer =>
      void (async () => {
        try {
          for await (const item of iterable as AsyncIterable<T>) {
            if (observer.closed) { return; }
            observer.next(item);
          }
          observer.complete();
        } catch (e) {
          observer.error(e);
        }
      })()
    ).pipe(
      scan<T, T[]>((items, item) => [...items, item], []),
      startWith([] as T[])
    );
  }

}
