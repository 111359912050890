import { Injectable } from '@angular/core';
import { BlobDeleteResponse } from '@azure/storage-blob';
import { from, OperatorFunction, Subject } from 'rxjs';
import { map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { ContainerDatasetRequest, BlobItem } from '../types/azure-storage';
import { BlobSharedViewStateService } from './blob-shared-view-state.service';
import { BlobStorageService } from './blob-storage.service';

@Injectable({
  providedIn: 'root'
})
export class BlobDeletesViewStateService {
  private deleteQueueInner$ = new Subject<string>();

  deletedItems$ = this.deleteQueue$.pipe(
    mergeMap(filename => this.deleteFile(filename)),
    this.blobState.scanEntries()
  );

  get deleteQueue$() {
    return this.deleteQueueInner$.asObservable();
  }

  constructor(
    private blobStorage: BlobStorageService,
    public blobState: BlobSharedViewStateService
  ) {}

  deleteItem(filename: string): void {
    this.deleteQueueInner$.next(filename);
  }

  private deleteFile = (filename: string) =>
    this.blobState.getStorageOptionsWithDataset().pipe(
      switchMap(options =>
        this.blobStorage
          .deleteBlobItem(filename, this.blobStorage.buildClient(options))
          .pipe(
            this.mapDeleteResponse(filename, options),
            this.blobState.finaliseBlobChange(options.datasetName)
          )
      )
    )

  private mapDeleteResponse = (
    filename: string,
    options: ContainerDatasetRequest
  ): OperatorFunction<BlobDeleteResponse, BlobItem> => source =>
    source.pipe(
      map(() => ({
        filename,
        datasetName: options.datasetName
      })),
      startWith({
        filename,
        datasetName: options.datasetName
      })
    )
}
