import { Component, OnInit } from '@angular/core';
import { RestService } from '../services/rest.service';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ToastService } from '@msi/cobalt';
import { HttpErrorResponse, JsonpClientBackend } from '@angular/common/http';
import { CcadminHelperService } from '../services/ccadmin-helper.service';
import { EnvService } from '../services/env.service';
import { MsiCascadeLevelOptions } from './Options/MsiCascadeLevelOptions';
import { ClosestMatchOptions } from './Options/ClosestMatchOptions';
import { BlockDisplayFormatOptions } from './Options/BlockDisplayFormatOptions';
import { BlockGeocodeLocationOptions } from './Options/BlockGeocodeLocationOptions';

@Component({
  selector: 'app-location-verify-mapping',
  templateUrl: './location-verify-mapping.component.html',
  providers: [RestService, CcadminHelperService],
})
export class LocationVerifyMappingComponent implements OnInit {
  ccConfig;
  Schema;
  LocationVerifyForm: FormGroup;
  TenetID;
  notAdmin = true;
  isEsri = false;
  isMsi = false;
  isUtah = false;
  isCustomEsriLocator = false;
  GeoCodertype;
  activegeodb;
  custTenantId: string;
  public readonly MsiCascadeLevelOptions = MsiCascadeLevelOptions;
  public readonly ClosestMatchOptions = ClosestMatchOptions;
  public readonly BlockDisplayFormatOptions = BlockDisplayFormatOptions;
  public readonly BlockGeocodeLocationOptions = BlockGeocodeLocationOptions;

  constructor(
    public rest: RestService,
    private toastService: ToastService,
    private ccadmin: CcadminHelperService,
    public env: EnvService
  ) { }
  public CCAdminVersion = this.env.admin;
  env1 = this.env.env1;

  async ngOnInit() {
    this.ccadmin.CCAdminVersion = 'admin';
    await this.getpermission();
  }
  public async uploaddata() {
    let validData = true;
    const matchDistanceUnitKey = 'MatchDistanceUnit';
    const matchDistanceKey = 'MatchDistance';
    const BlockKeyword = 'BlockKeyword';
    const LocationKey = 'Location';
    const ExtentKey = 'Extent';
    const hasLocationKey = 'hasLocation';
    const hasExtentKey = 'hasExtent';
    const requestKey = 'hasTimeout';
    const requesttimeoutKey = 'RequestTimeout';
    const UserNameKey = 'UserName';
    const PasswordKey = 'Password';
    const EndpointKey = 'EndPoint';
    const TokenEndpointKey = 'TokenEndpoint';
    const RequiresAuthenticationKey = 'RequiresAuthentication';
    const unitOfDistance: string = this.LocationVerifyForm.value[matchDistanceUnitKey];
    const distance: number = this.LocationVerifyForm.value[matchDistanceKey];
    const blockDisplay: string = this.LocationVerifyForm.value[BlockKeyword];
    const hasLocation = this.LocationVerifyForm.get(hasLocationKey).value;
    const hasExtent = this.LocationVerifyForm.get(hasExtentKey).value;
    const location = this.LocationVerifyForm.get(LocationKey);
    const extent = this.LocationVerifyForm.get(ExtentKey);
    const hasrequest = this.LocationVerifyForm.get(requestKey);
    const requesttimeout = this.LocationVerifyForm.get(requesttimeoutKey);
    const userName = this.LocationVerifyForm.get(UserNameKey);
    const pwd = this.LocationVerifyForm.get(PasswordKey);
    const endpoint = this.LocationVerifyForm.get(EndpointKey);
    const tokenEndpoint = this.LocationVerifyForm.get(TokenEndpointKey);
    const requiresAuthentication = this.LocationVerifyForm.get(RequiresAuthenticationKey);

    if (this.LocationVerifyForm.status === 'INVALID') {
      this.toastService.error('Please fix any data validation errors!');
      return;
    }

    if (hasLocation) {
      if (!location.valid) {
        this.toastService.error('Only numbers are acceptable for \'Location For Suggest\'');
      } else {
        if (
          location.value.Location_latitude > 90 ||
          location.value.Location_longitude > 180 ||
          location.value.Location_longitude < -180 ||
          location.value.Location_latitude < -90
        ) {
          this.toastService.error('Improper coordinate for \'Location For Suggest\' ');
          validData = false;
        }
      }
    } else {
      this.LocationVerifyForm.value.Location = null;
    }
    if (hasExtent) {
      if (!extent.valid) {
        this.toastService.error('Only numbers are acceptable for \'Home Extents\' ');
      } else {
        if (
          extent.value.Extent_latitude_min > 90 ||
          extent.value.Extent_longitude_min > 180 ||
          extent.value.Extent_longitude_min < -180 ||
          extent.value.Extent_latitude_min < -90
        ) {
          this.toastService.error('Improper coordinate for \'Home Extents\' Min coordinate ');
          validData = false;
        }
        if (
          extent.value.Extent_latitude_max > 90 ||
          extent.value.Extent_longitude_max > 180 ||
          extent.value.Extent_longitude_max < -180 ||
          extent.value.Extent_latitude_max < -90
        ) {
          this.toastService.error('Improper coordinate for \'Home Extents\' Max coordinate ');
          validData = false;
        }
      }
    } else {
      this.LocationVerifyForm.value.Extent = null;
    }
    if (this.GeoCodertype === 'MSIGeocoder') {
      if (!blockDisplay.match(/^[a-zA-ZÇ-Ñ]+$/)) {
        validData = false;
        this.toastService.error('Block Keyword must contain letters only and no special characters, numbers or spaces');
      }
      if (unitOfDistance === 'Foot' && distance > 26400) {
        validData = false;
        this.toastService.error('Maximum distance allowed in feet is 26400.');
      } else if (unitOfDistance === 'Mile' && distance > 5) {
        validData = false;
        this.toastService.error('Maximum distance allowed in miles is 5.');
      } else if (unitOfDistance === 'Meter' && distance > 8046) {
        validData = false;
        this.toastService.error('Maximum distance allowed in meters is 8046.');
      } else if (unitOfDistance === 'Kilometer' && distance > 8) {
        validData = false;
        this.toastService.error('Maximum distance allowed in kilometers is 8.');
      }
    } else if (this.GeoCodertype === 'UtahGeocoder') {
      if (hasrequest.value !== true) {
        this.LocationVerifyForm.value.RequestTimeout = 0;
      } else {
        if (requesttimeout.value < 0.5) {
          validData = false;
          this.toastService.error('Minimum request timeout is 0.5 seconds. To disable uncheck the above box.');
        }
        this.LocationVerifyForm.value.RequestTimeout = requesttimeout.value * 1000;
      }
    } else if (this.GeoCodertype === 'CustomEsriLocator') {
      if ((!this.ValidateInput(endpoint.value) || requiresAuthentication.value === undefined)
        || (requiresAuthentication.value === true && (!this.ValidateInput(userName.value) || !this.ValidateInput(pwd.value) || !this.ValidateInput(tokenEndpoint.value)))) {
        validData = false;
        this.toastService.error('Customer settings are required.');
      }

      if (requiresAuthentication.value !== true) {
        this.LocationVerifyForm.value.TokenEndpoint = null;
        this.LocationVerifyForm.value.UserName = null;
        this.LocationVerifyForm.value.Password = null;
      }
    }
    let config;
    config = this.Schema;
    delete config.BlockGeoCodeLocation;
    let userSelectedActiveDB;
    let proceedForActiveDB = false;
    console.log(this.LocationVerifyForm);
    if (validData) {
      const keylist = Object.keys(this.LocationVerifyForm.value);
      keylist.forEach((key) => {
        if (key.toString() !== 'GeoDatabase') {
          config[key.toString()] = this.LocationVerifyForm.value[key.toString()];
        } else {
          userSelectedActiveDB = this.LocationVerifyForm.value[key.toString()];
        }
      });
      if (this.GeoCodertype !== 'UtahGeocoder') {
        delete config.RequestTimeout;
      }

      await this.rest
        .setAgencyGeocoderConfiguration(this.rest.customerID, this.rest.agencyID, this.GeoCodertype, config)
        .toPromise()
        .then(
          (results) => {
            this.toastService.success('Settings updated succesfully!');
            proceedForActiveDB = true;
          },
          (e: HttpErrorResponse) => {
            this.toastService.error(`Failed to save settings due to error: ${e.error}.`);
          }
        );

      if (this.activegeodb !== userSelectedActiveDB && proceedForActiveDB) {
        await this.rest
          .getCustomerTenantId(this.rest.customerID, 'Address')
          .toPromise()
          .then(
            (tenant) => {
              this.custTenantId = tenant;

              this.rest
                .setTenantActiveDB(this.custTenantId, userSelectedActiveDB, this.GeoCodertype)
                .toPromise()
                .then(
                  (results) => {
                    this.GetActiveDB();
                  },
                  (e: HttpErrorResponse) => {
                    this.toastService.error(`Failed to save active db: ${e.error}.`);
                  }
                );
            },
            (e: HttpErrorResponse) => {
              if (e.status !== 404) {
                this.toastService.error(`Failed to get tenant for customer : ${e.error}.`);
              } else {
                this.toastService.error(`Customer not onboarded.`);
              }
            }
          );
      }
    }
  }
  private async getpermission() {
    let AddressName;
    if (this.env1 === '.dev.') {
      AddressName = 'GeoVerificationDev';
    } else {
      AddressName = 'GeoVerification';
    }
    /* istanbul ignore if */
    if (this.CCAdminVersion === 'admin2.') {
      /* istanbul ignore if */
      this.rest.getMe2().subscribe((data) => {
            const val = sessionStorage.getItem('customerID');
            this.rest.agencyID = val;
            this.rest.customerID = val;
            console.log('customerid is ' + this.rest.customerID);
            data.me.permissions.forEach((element) => {
          if (element.serviceSystemName === AddressName && element.permissionName === 'Edit GeoVerification Settings') {
            this.notAdmin = false;
          }
        });

        this.createForm();
        this.GetGeoCoderType();
        this.GetAgencyConfiguration();
      });
    } else {
      this.rest.getMe().subscribe((data) => {
        this.rest.agencyID = data._links.agency.agencyId;
        this.rest.customerID = data._links.customer.customerId;
        if (data.services[AddressName]) {
          if (data.services[AddressName].permissions) {
            if (
              data.services[AddressName].permissions.find((i) => i.systemName === 'can-edit-settings') !== undefined
            ) {
              this.notAdmin = false;
            }
          }
        } else {
          this.notAdmin = true;
          this.toastService.error('UserGroup does not have Geo Verification Capability');
        }

        this.ccadmin.CCAdminIsImpersonating(data);
        this.createForm();
        this.GetGeoCoderType();
        this.GetAgencyConfiguration();
      });
    }
  }
  private async GetAgencyConfiguration() {
    await this.rest
      .getAgencyConfiguration(this.rest.customerID, this.rest.agencyID)
      .toPromise()
      .then(
        async (config) => {
          this.Schema = JSON.parse(config);
          this.setFormValues();
        },
        (e: HttpErrorResponse) => {
          if (e.status !== 404) {
            this.toastService.error(`Failed to get agency settings due to error: ${e.error}.`);
          } else {
            this.toastService.warning('Agency does not have settings stored.');
          }
        }
      );
  }
  private async GetGeoCoderType() {
    await this.rest
      .getCustomerGeocoderType(this.rest.customerID)
      .toPromise()
      .then(
        async (type) => {
          this.GeoCodertype = type;
          switch (type) {
            case 'MSIGeocoder':
              this.isMsi = true;
              await this.GetActiveDB();
              break;
            case 'ESRIOnline':
              this.isEsri = true;
              break;
            case 'UtahGeocoder':
              this.isUtah = true;
            case 'CustomEsriLocator':
              this.isCustomEsriLocator = true;
              break;
            default:
              this.toastService.warning(`There are no settings to display for ${type}`);
              break;
          }
        },
        (e: HttpErrorResponse) => {
          if (e.status !== 404) {
            this.toastService.error(`Failed to get customer geocoder type due to error: ${e.error}.`);
          } else {
            this.toastService.warning('Customer does not have a geocoder type set.');
          }
        }
      );
  }

  private createForm(): void {
    this.LocationVerifyForm = new FormGroup({
      MatchDistance: new FormControl({ value: '', disabled: this.notAdmin }, [Validators.min(0)]),
      MatchDistanceUnit: new FormControl({ value: '', disabled: this.notAdmin }),
      IsReturnClosestMatch: new FormControl({ value: '', disabled: this.notAdmin }),
      WildcardCharacters: new FormControl({ value: '', disabled: this.notAdmin }),
      IntersectionDelimiterCharacters: new FormControl({ value: '', disabled: this.notAdmin }),
      MaxReturnResults: new FormControl({ value: '', disabled: this.notAdmin }, [Validators.min(0)]),
      IsDisplayHighAndLowCrossStreets: new FormControl({ value: '', disabled: this.notAdmin }),
      IsDisplayHighAndLowCrossBlocks: new FormControl({ value: '', disabled: this.notAdmin }),
      IsDisplayBlockNumbersForIntersections: new FormControl({ value: '', disabled: this.notAdmin }),
      DisableAutoSelectVerifiedLocation: new FormControl({ value: '', disabled: this.notAdmin }),
      TrimLeadingZeroesInHouseNumEnabled: new FormControl({ value: '', disabled: this.notAdmin }),
      RoutePremiseLocationsToCenterline: new FormControl({ value: '', disabled: this.notAdmin }),
      IsDisplayTableOfContent: new FormControl({ value: '', disabled: this.notAdmin }),
      CascadeLevel: new FormControl({ value: '', disabled: this.notAdmin }),
      BlockKeyword: new FormControl({ value: '', disabled: this.notAdmin }),
      BlockDisplayFormat: new FormControl({ value: '', disabled: this.notAdmin }),
      BlockGeocodeLocation: new FormControl({ value: '', disabled: this.notAdmin }),
      IsRetainInvalidPremiseNumber: new FormControl({ value: '', disabled: this.notAdmin }),
      GeoDatabase: new FormControl({ value: '', disabled: this.notAdmin }),
      hasExtent: new FormControl({ value: false, disabled: this.notAdmin }),
      hasLocation: new FormControl({ value: false, disabled: this.notAdmin }),
      CascadeGeoCoder: new FormControl({ value: false, disabled: this.notAdmin }),
      UseWhat3Words: new FormControl({ value: false, disabled: this.notAdmin }),
      RequestTimeout: new FormControl({ value: 0, disabled: this.notAdmin }, [Validators.min(0)]),
      hasTimeout: new FormControl({ value: false, disabled: this.notAdmin }),
      Location: new FormGroup({
        Location_longitude: new FormControl({ value: null, disabled: this.notAdmin }),
        Location_latitude: new FormControl({ value: null, disabled: this.notAdmin }),
      }),
      Extent: new FormGroup({
        Extent_longitude_min: new FormControl({ value: null, disabled: this.notAdmin }),
        Extent_latitude_min: new FormControl({ value: null, disabled: this.notAdmin }),
        Extent_longitude_max: new FormControl({ value: null, disabled: this.notAdmin }),
        Extent_latitude_max: new FormControl({ value: null, disabled: this.notAdmin }),
      }),
      UserName: new FormControl({ value: '', disabled: this.notAdmin }),
      Password: new FormControl({ value: '', disabled: this.notAdmin }),
      EndPoint: new FormControl({ value: '', disabled: this.notAdmin }),
      TokenEndpoint: new FormControl({ value: '', disabled: this.notAdmin }),
      RequiresAuthentication: new FormControl({ value: false, disabled: this.notAdmin })
    });
  }
  public setFormValues() {
    let config;
    config = this.Schema;
    if (config.CascadeLevel) {
      config.CascadeLevel = config.CascadeLevel.replace(/ /g, '');
    }
    if (!config.hasOwnProperty('RequiresAuthentication')) {
      config['RequiresAuthentication'] = false;
    }
    this.LocationVerifyForm.patchValue({
      MatchDistance: config.MatchDistance,
      MatchDistanceUnit: config.MatchDistanceUnit,
      IsReturnClosestMatch: config.IsReturnClosestMatch,
      WildcardCharacters: config.WildcardCharacters,
      IntersectionDelimiterCharacters: config.IntersectionDelimiterCharacters,
      MaxReturnResults: config.MaxReturnResults,
      IsDisplayHighAndLowCrossStreets: config.IsDisplayHighAndLowCrossStreets,
      IsDisplayHighAndLowCrossBlocks: config.IsDisplayHighAndLowCrossBlocks,
      IsDisplayBlockNumbersForIntersections: config.IsDisplayBlockNumbersForIntersections,
      DisableAutoSelectVerifiedLocation: config.DisableAutoSelectVerifiedLocation,
      TrimLeadingZeroesInHouseNumEnabled: config.TrimLeadingZeroesInHouseNumEnabled,
      RoutePremiseLocationsToCenterline: config.RoutePremiseLocationsToCenterline,
      IsDisplayTableOfContent: config.IsDisplayTableOfContent,
      CascadeLevel: config.CascadeLevel,
      BlockKeyword: config.BlockKeyword,
      BlockDisplayFormat: config.BlockDisplayFormat,
      BlockGeocodeLocation: config.BlockGeocodeLocation,
      IsRetainInvalidPremiseNumber: config.IsRetainInvalidPremiseNumber,
      GeoDatabase: this.activegeodb,
      CascadeGeoCoder: config.CascadeGeoCoder,
      UseWhat3Words: config.UseWhat3Words,
      hasExtent: config.Extent ? true : false,
      hasLocation: config.Location ? true : false,
      RequestTimeout: config.RequestTimeout / 1000,
      UserName: config.UserName,
      Password: config.Password,
      EndPoint: config.EndPoint,
      TokenEndpoint: config.TokenEndpoint,
      RequiresAuthentication: config.RequiresAuthentication ?? false
    });
    if (config.RequestTimeout && config.RequestTimeout > 0) {
      this.LocationVerifyForm.patchValue({
        hasTimeout: true,
      });
    } else {
      this.LocationVerifyForm.patchValue({
        hasTimeout: false,
      });
    }

    if (!config.BlockGeocodeLocation) {
      this.LocationVerifyForm.patchValue({
        BlockGeocodeLocation: config.BlockGeoCodeLocation,
      });
    }
    if (this.LocationVerifyForm.value.hasLocation === true) {
      (this.LocationVerifyForm.get('Location') as FormGroup).setValue({
        Location_longitude: config.Location.Location_longitude,
        Location_latitude: config.Location.Location_latitude,
      });
    } else {
      this.LocationVerifyForm.value.Location = null;
    }
    if (this.LocationVerifyForm.value.hasExtent === true) {
      (this.LocationVerifyForm.get('Extent') as FormGroup).setValue({
        Extent_longitude_min: config.Extent.Extent_longitude_min,
        Extent_latitude_min: config.Extent.Extent_latitude_min,
        Extent_longitude_max: config.Extent.Extent_longitude_max,
        Extent_latitude_max: config.Extent.Extent_latitude_max,
      });
    } else {
      this.LocationVerifyForm.value.Extent = null;
    }
  }
  public async testCustomEsriLocator() {
    let validData = true;
    const UserNameKey = 'UserName';
    const PasswordKey = 'Password';
    const EndpointKey = 'EndPoint';
    const TokenEndpointKey = 'TokenEndpoint';
    const RequiresAuthenticationKey = 'RequiresAuthentication';
    const userName = this.LocationVerifyForm.get(UserNameKey);
    const pwd = this.LocationVerifyForm.get(PasswordKey);
    const endpoint = this.LocationVerifyForm.get(EndpointKey);
    const tokenEndpoint = this.LocationVerifyForm.get(TokenEndpointKey);
    const requiresAuthentication = this.LocationVerifyForm.get(RequiresAuthenticationKey);
    if (this.LocationVerifyForm.status === 'INVALID') {
      this.toastService.error('Please fix any data validation errors!');
      return;
    }

    if ((!this.ValidateInput(endpoint.value) || requiresAuthentication.value === undefined)
      || (requiresAuthentication.value === true && (!this.ValidateInput(userName.value) || !this.ValidateInput(pwd.value) || !this.ValidateInput(tokenEndpoint.value)))) {
      validData = false;
      this.toastService.error('Customer settings are required.');
    }

    let config;
    config = this.Schema;
    if (validData) {
      const keylist = Object.keys(this.LocationVerifyForm.value);
      keylist.forEach((key) => {
        if (key.toString() === 'EndPoint' || key.toString() === 'RequiresAuthentication' || key.toString() === 'TokenEndpoint'
          || key.toString() === 'UserName' || key.toString() === 'Password') {
          config[key.toString()] = this.LocationVerifyForm.value[key.toString()];
        }
      });

      await this.rest.TestGeocoderConfiguration(this.rest.customerID, this.rest.agencyID, config)
        .toPromise()
        .then((results) => {
          this.toastService.success('Test successful.')
        },
          (e: HttpErrorResponse) => {
            this.toastService.error(`An error occurred: ${e.error}`);
          }
        );
    }
  }
  private async GetActiveDB() {
    await this.rest
      .get_ActiveDB(this.rest.customerID)
      .toPromise()
      .then(
        async (result) => {
          this.activegeodb = result;
          this.setFormValues();
        },
        (e: HttpErrorResponse) => {
          this.toastService.error(`Failed to get active db due to error: ${e.error}.`);
        }
      );
  }

  private ValidateInput(input: string): boolean {
    if (input === null || input.trim() === '') {
      return false;
    }
    return true;
  }


  public isReturnClosestMatch(): boolean {
    return this.getFormControl('IsReturnClosestMatch').value === ClosestMatchOptions.ReturnClosest;
  }

  public isListByProximity(): boolean {
    return this.getFormControl('IsReturnClosestMatch').value === ClosestMatchOptions.ListByProximity;
  }

  public isDisableReverseGeocoding(): boolean {
    return this.getFormControl('IsReturnClosestMatch').value === ClosestMatchOptions.DisableReverseGeocoding;
  }

  public isAsterisk(): boolean {
    return this.getFormControl('WildcardCharacters').value === '*';
  }

  public isPercentage(): boolean {
    return this.getFormControl('WildcardCharacters').value === '%';
  }

  public isAtSign(): boolean {
    return this.getFormControl('IntersectionDelimiterCharacters').value === '@';
  }

  public isAmpersand(): boolean {
    return this.getFormControl('IntersectionDelimiterCharacters').value === '&';
  }

  public isForwardSlash(): boolean {
    return this.getFormControl('IntersectionDelimiterCharacters').value === '/';
  }

  public isNeverCascade(): boolean {
    return this.getFormControl('CascadeLevel').value === MsiCascadeLevelOptions.NeverCascade;
  }

  public isCascadeIfNoRowsFound(): boolean {
    return this.getFormControl('CascadeLevel').value === MsiCascadeLevelOptions.CascadeIfNoRowsFound;
  }

  public isAlwaysCascade(): boolean {
    return this.getFormControl('CascadeLevel').value === MsiCascadeLevelOptions.AlwaysCascade;
  }

  private getFormControl(controlName: string): AbstractControl {
    return this.LocationVerifyForm.get(controlName);
  }

  public isBlockDisplaySharpSign(): boolean {
    return this.getFormControl('BlockDisplayFormat').value === BlockDisplayFormatOptions.SharpSign;
  }

  public isBlockDisplaySt(): boolean {
    return this.getFormControl('BlockDisplayFormat').value === BlockDisplayFormatOptions.St;
  }

  public isBlockGeocodeLocationStart(): boolean {
    return this.getFormControl('BlockGeocodeLocation').value === BlockGeocodeLocationOptions.Start;
  }

  public isBlockGeocodeLocationMiddle(): boolean {
    return this.getFormControl('BlockGeocodeLocation').value === BlockGeocodeLocationOptions.Middle;
  }

  public isBlockGeocodeLocationEnd(): boolean {
    return this.getFormControl('BlockGeocodeLocation').value === BlockGeocodeLocationOptions.End;
  }

}
