import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadTypeHelperService {
  filetype$: Observable<any>;
  private fileuploadtype = new Subject<any>();

  constructor() {
      this.filetype$ = this.fileuploadtype.asObservable();
  }

  setuploadtype(data) {
      this.fileuploadtype.next(data);
  }
}
