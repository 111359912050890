import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  MonoTypeOperatorFunction,
  Observable,
  OperatorFunction
} from 'rxjs';
import {
  filter,
  finalize,
  map,
  scan,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import {
  BlobItem,
  BlobStorageRequest,
  Dictionary,
  ContainerDatasetRequest
} from '../types/azure-storage';
import { BlobStorageService } from './blob-storage.service';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class BlobSharedViewStateService {
  private serviceName;
  private selectedDatasetInner$ = new BehaviorSubject<string>(undefined);

  datasets$ = this.getStorageOptions().pipe(
    switchMap(options => this.blobStorage.getDatasets(options))
  );
  itemsInDataset$ = this.selectedDataset$.pipe(
    filter(datasetName => !!datasetName),
    switchMap(datasetName =>
      this.getStorageOptions().pipe(
        switchMap(options =>
          this.blobStorage.listItemsInDataset({
            ...options,
            datasetName
          })
        )
      )
    )
  );

  get selectedDataset$() {
    return this.selectedDatasetInner$.asObservable();
  }

  constructor(
    private sasGenerator: RestService,
    private blobStorage: BlobStorageService
  ) {}

  getContainerItems(datasetName: string): void {
    this.selectedDatasetInner$.next(datasetName);
  }
  public setService(serviceName: string) {
    this.serviceName = serviceName;
  }

  public GetService(): string {
    return this.serviceName;
  }
  finaliseBlobChange = <T>(
    dataset: string
  ): MonoTypeOperatorFunction<T> => source =>
    source.pipe(
      finalize(
        () =>
          this.selectedDatasetInner$.value === dataset &&
          this.selectedDatasetInner$.next(dataset)
      )
    )

  scanEntries = <T extends BlobItem>(): OperatorFunction<T, T[]> => source =>
    source.pipe(
      map(item => ({
        [`${item.datasetName}-${item.filename}`]: item
      })),
      scan<Dictionary<T>>(
        (items, item) => ({
          ...items,
          ...item
        })
      ),
      map(items => Object.values(items))
    )

  getStorageOptions(): Observable<BlobStorageRequest> {
    return this.sasGenerator.get_sasToken(this.serviceName);

  }

  getStorageOptionsWithDataset(): Observable<ContainerDatasetRequest> {
    return this.getStorageOptions().pipe(
      withLatestFrom(this.selectedDataset$),
      map(([options, datasetName]) => ({ ...options, datasetName }))
    );
  }

}
