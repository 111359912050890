import { CaseService } from '@msi/cobalt/file-upload/file-upload.service';
import { AssetType, FileUploadManager, States, ToastService } from '@msi/cobalt';

/**
 * Given size in bytes, format for human consumption
 * Examples: 100KB, 34MB, 1TB
 */
function getHumanSize(size: number): string {
  if (size < 1000) {
    return Math.round(size) + 'B';
  }
  size /= 1000;
  if (size < 1000) {
    return Math.round(size) + 'KB';
  }
  size /= 1000;
  if (size < 1000) {
    return Math.round(size) + 'MB';
  }
  size /= 1000;
  if (size < 1000) {
    return Math.round(size) + 'GB';
  }
  size /= 1000;
  if (size < 1000) {
    return Math.round(size) + 'TB';
  }
  return '>999TB';
}

function getIcon(name: string) {
  if (!name) { return 'ic_file'; }
  const split = name.split('.');
  const ext = split[split.length - 1].toLowerCase();

  const images = ['jpg', 'jpeg', 'png', 'jfif', 'gif', 'webp', 'tiff', 'psd', 'raw', 'bmp', 'heif', 'indd', 'svg', 'ai', 'eps'];
  const videos = ['avi', 'mp4', 'flv', 'wmv', 'mov', 'webm'];

  if (images.find(x => x === ext)) { return 'ic_attachment_picture'; }
  if (videos.find(x => x === ext)) { return 'ic_attachment_video'; }
  return 'ic_file';
}

export class CoreGISFileUploadManager extends FileUploadManager {
  assets: any[] = [];

  constructor(service: CaseService, private toastService: ToastService) {
    super(service, false);
    this.init();
  }

  addFiles($f: HTMLInputElement) {
    if ($f.files && $f.files.length) {
      Array.prototype.forEach.call($f.files, (file) => {
        const fileSize =  getHumanSize(file.size);
        // File should be less than or equal to 2GB(2147483648 Bytes).
        if (file.size <= 2147483648)
        {
        const newAsset = {
          caseId: this.caseService.getCaseId(),
          name: file.name,
          originalName: file.name,
          size: file.size,
          count: 1,
          type: AssetType.SINGLEFILE,
          progress: 0,
          loaded: 0,
          total: file.size,
          countUploaded: 0,
          state: States.START,
          message: '',
          status: '',
          humanSize: fileSize,
          files: [
            {
              name: file.name,
              path: '',
              size: file.size,
              type: '',
              state: 'start',
              humanSize: fileSize,
              file: file as File,
              sizeOfChunks: []
            }
          ],
          set(...args) { },
          first() {
            return this.files[0];
          },
          getIconName() { return getIcon(file.name); }
        };

        this.assets.push(newAsset);
    }
    else
    {
      this.toastService.error(`File ${file.name} cannot be uploaded as its size exceeds 2GB.`);
    }
      });

      this.refreshAssets();
    }
  }
}
