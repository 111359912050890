import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestService } from '../services/rest.service';
import { Router } from '@angular/router';
import { ToastService } from '@msi/cobalt';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, Validators } from '@angular/forms';
import { CcadminHelperService } from '../services/ccadmin-helper.service';

@Component({
  selector: 'app-boundary-edit-tenant-id',
  templateUrl: './boundary-edit-tenant-id.component.html',
})
export class BoundaryEditTenantIDComponent implements OnInit, OnDestroy {
  TenantId;
  agencyId;
  TenantId1: string;
  getMeLoader;
  boundaryTenantLoader;
  sizeselect = new FormControl();
  sizeData = {
    Small: 'S',
    Medium: 'M',
    Large: 'L',
  };
  isExist = false;
  isNewTenantId = true;
  ccConfig;
  custccConfig;
  Schema;
  custSchema;
  tenantSize;
  custIdselect = new FormControl();
  custTenantId: string;
  customerIddropdown;
  objectKeys = Object.keys;
  CanSetTenantId = false;
  constructor(public rest: RestService, private toastService: ToastService, private ccAdmin: CcadminHelperService) {}
  ngOnDestroy(): void {
    if (this.getMeLoader !== undefined) {
      this.getMeLoader.unsubscribe();
    }
    if (this.boundaryTenantLoader !== undefined) {
      this.boundaryTenantLoader.unsubscribe();
    }
  }

  async ngOnInit() {
    this.rest.UpdateUrl();
    document.getElementById('anotherCust').style.display = 'none';
    this.getMeLoader = this.rest.getMe().subscribe((data) => {
      this.ccAdmin.CCAdminIsImpersonating(data);
      this.CanSetTenantId = this.ccAdmin.IsSuperAdmin;
      if (!this.CanSetTenantId) {
        this.toastService.error(`You do not have valid permissions to edit Tenant ID. You must be an MSI Super-Admin.`);
      }
      this.rest.getCustomerList('Boundary').subscribe((x) => {
        this.customerIddropdown = x;
        this.customerIddropdown.sort();
      });
      this.agencyId = data._links.agency.agencyId;
      this.TenantId = data._links.customer.customerId;

      this.rest.gettenantId('Boundary').subscribe(
        (x) => {
          if (x === '') {
            this.toastService.warning(`Customer does not have a stored TenantId, see suggested TenantId`);
            this.TenantId1 = this.rest.customerID;
            const re = /\./gi;
            this.TenantId1 = this.TenantId1.toLowerCase().replace(re, '-');
            this.TenantId = this.TenantId1;
          } else {
            this.isNewTenantId = false;
            this.TenantId = x;
            this.getTenantSize();
          }
        },
        (e: HttpErrorResponse) => {
          if (e.status === 404) {
            this.toastService.warning(`Customer does not have a stored TenantId, see suggested TenantId`);
            this.TenantId1 = this.rest.customerID;
            const re = /\./gi;
            this.TenantId1 = this.TenantId1.toLowerCase().replace(re, '-');
            this.TenantId = this.TenantId1;
          } else {
            this.toastService.error(`Failed to get Customer TenantId.`);
          }
        }
      );
    });
  }

  SetTenantId() {
    const tenantId = this.TenantId;
    const submittedTenantSize = this.sizeselect.value;
    if (tenantId.match(/^[0-9a-z](?!.*-{2})[0-9a-z-]{1,61}[0-9a-z]$/)) {
      if (this.isNewTenantId === true) {
        if (submittedTenantSize !== '') {
          const body = {
            CustomerId: this.rest.customerID,
            isExistingTenant: this.TenantId === tenantId,
            TenantId: tenantId,
            serviceName: 'Boundary',
          };
          this.rest.post_addcustomertenant(body).subscribe(
            (x) => {
              this.rest.CustomerOnBoarding(this.TenantId, submittedTenantSize).subscribe(
                (y) => {
                  this.isNewTenantId = false;
                  this.toastService.success(`Customer TenantId set as ${tenantId}.`, undefined, {
                    autoDismiss: true && 3000,
                  });
                },
                (e: HttpErrorResponse) => {
                  this.rest.post_deletecustomertenant('Boundary').subscribe(
                    (o) => {},
                    (err: HttpErrorResponse) => {
                      this.toastService.error(`Failed to delete Customer TenantId.`);
                    }
                  );
                  this.toastService.error(`Failed to onboard Tenant ${this.TenantId}.`);
                }
              );
            },
            (e: HttpErrorResponse) => {
              this.rest.post_deletecustomertenant('Boundary');
              this.toastService.error(`Failed to set Customer TenantId  as ${tenantId}..`);
            }
          );
        } else {
          this.toastService.error(`Please select tenant size.`);
        }
      } else {
        const oldTenant = this.TenantId;
        const body = {
          CustomerId: this.rest.customerID,
          isExistingTenant: this.TenantId === tenantId,
          TenantId: tenantId,
          serviceName: 'Boundary',
        };
        this.rest.post_updatecustomertenant(body).subscribe(
          (x) => {
            if (oldTenant !== tenantId || submittedTenantSize !== this.tenantSize) {
              this.UpdateTenantSize(oldTenant, tenantId, submittedTenantSize);
            } else {
              this.toastService.success(`Customer TenantId updated to ${tenantId}.`, undefined, {
                autoDismiss: true && 3000,
              });
            }
          },
          (e: HttpErrorResponse) => {
            this.toastService.error(`Failed to update Customer TenantId to ${tenantId} due to error: ${e.error}`);
          }
        );
      }
    }
  }

  UpdateTenantSize(oldTenantId: string, newTenantId: string, size: string) {
    const tenantSize = size;
    const updateTenatSize = {
      TenantSize: tenantSize,
      TenantId: newTenantId,
    };
    if (tenantSize !== '') {
      this.rest.updateTenantSize(updateTenatSize, oldTenantId).subscribe(
        (res) => {
          this.toastService.success(`Customer TenantId updated.`, undefined, {
            autoDismiss: true && 3000,
          });
          console.log(res);
        },
        (error) => {
          this.toastService.error(`Failed to update Tenant  ${this.TenantId}  Size.`);
          console.log(error);
        }
      );
    } else {
      this.toastService.error(`Please select tenant size.`);
    }
  }

  getTenantSize() {
    this.boundaryTenantLoader = this.rest.getTenantSize(this.TenantId).subscribe(
      (res) => {
        console.log(res);
        let tenantInfo: any;
        tenantInfo = res;
        this.isExist = true;
        this.sizeselect.setValue(tenantInfo.tenantSize);
        this.tenantSize = tenantInfo.tenantSize;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  UseExistingTenant() {
    this.toastService.warning(
      '​Data Privacy Warning!! Using another customer\'s tenant Id should only be allowed for agencies of the same customer!'
    );
    document.getElementById('anotherCust').style.display = 'block';
    document.getElementById('uniquetenant').style.display = 'none';
  }
  UseUniqueTenant() {
    document.getElementById('anotherCust').style.display = 'none';
    document.getElementById('uniquetenant').style.display = 'block';
  }
  public validatetenantId() {
    if (this.custIdselect.value !== null && this.custIdselect.value !== '') {
      this.rest.getCustomerTenantId(this.custIdselect.value, 'Boundary').subscribe((tenant) => {
        this.custTenantId = 'TenantId: ' + tenant;
      });
    }
  }
  SetOtherCustomersTenantId() {
    const cid = this.custIdselect.value;
    this.rest.getCustomerTenantId(cid, 'Boundary').subscribe(
      (x) => {
        if (this.isNewTenantId === true) {
          const body = {
            CustomerId: this.rest.customerID,
            isExistingTenant: true,
            TenantId: x.toString(),
            serviceName: 'Boundary',
          };
          this.rest.post_addcustomertenant(body).subscribe(
            (y) => {
              this.toastService.success(`Customer TenantId set as ${x}.`, undefined, {
                autoDismiss: true && 3000,
              });
            },
            (e: HttpErrorResponse) => {
              this.toastService.error(`Failed to update Customer TenantId due to error: ${e.error}`);
            }
          );
        } else {
          const oldTenant = this.TenantId;
          const body = {
            CustomerId: this.rest.customerID,
            isExistingTenant: true,
            TenantId: x.toString(),
            serviceName: 'Boundary',
          };
          this.rest.post_updatecustomertenant(body).subscribe(
            (y) => {
              this.toastService.success(`Customer TenantId updated to ${x}.`, undefined, {
                autoDismiss: true && 3000,
              });
            },
            (e: HttpErrorResponse) => {
              this.toastService.error(`Failed to set Customer TenantId set as ${x}.`);
            }
          );
        }
      },
      (e: HttpErrorResponse) => {
        if (e.status === 404) {
          this.toastService.error(`Entered CustomerID does not have a tenantId.`);
        } else {
          this.toastService.error(`Unable to retrieve Customer TenantId.`);
        }
      }
    );
  }
}
