import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvService, EnvType } from './env.service';
import { map, catchError } from 'rxjs/operators';


interface MeResponse {
  services: {
    GeoVerification: {},
    GeoVerificationDev: {},
    GeoDataIngestDev: {},
    GeoDataIngest: {},
    GisBoundaryConfig: {},
    GisBoundaryConfigDev: {},
    GisBoundaryConfigUSGov: {}

  };
}
@Injectable({
  providedIn: 'root'
})
export class PrivilegesService {

  constructor(private http: HttpClient, private envService: EnvService) {
  }

  private getMeUrl(): string {
   const envPrefixes = this.envService.env;
   const CCAdminVersion = this.envService.admin;
   const domainExtension = this.envService.domainExtension;

   if (CCAdminVersion === 'admin2.')
   {
      return `https://admin-api${envPrefixes}commandcentral.${domainExtension}/api/admin-v2/me?with-relations=ALL`;
   }
   else
   {
      return `https://admin-api${envPrefixes}commandcentral.${domainExtension}/api/admin/me`;
   }
  }

  canAccess(): Observable<boolean> {
    if (this.envService.isLocal()) {
      return of(true);
    } else {
      return this.http.get(this.getMeUrl()).pipe(
        map((resp: MeResponse) => {
          if (resp.services.GeoVerification || resp.services.GeoDataIngest || resp.services.GisBoundaryConfig) {
            console.log('User priviledged');
            return true;
          } else {
            console.log('User not priviledged');
            return false;
          }
        }),
        catchError( () => {
          console.log('could not determine user priveleges.');
          return of(false);
        })
      );
    }
  }
}
