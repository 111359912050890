import { Routes, RouterModule } from '@angular/router';
import { LocationVerifyMappingComponent } from './location-verify-mapping/location-verify-mapping.component';
import { AuthGuardService } from './services/auth-guard.service';
import { NgModule } from '@angular/core';
import { GeodatabaseComponent } from './geodatabase/geodatabase.component';
import { AuthErrorComponent } from './components/auth-error/auth-error.component';
import { AccessErrorComponent } from './components/access-error/access-error.component';
import { EditTenantIDComponent } from './edit-tenant-id/edit-tenant-id.component';
import {EditExternalTenantComponent} from './edit-external-tenant/edit-external-tenant.component';
import {BoundaryComponent} from './boundary/boundary.component';
import { BoundaryEditTenantIDComponent } from './boundary-edit-tenant-id/boundary-edit-tenant-id.component';
import { AppComponent } from './app.component';
import {MsiConfigComponent} from './msi-config/msi-config.component';
const routes: Routes = [
{
    path: 'geodatabase',
    component: GeodatabaseComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'GeoDatabase',
    }
}, {
  path: 'geodatabase/gov',
  component: GeodatabaseComponent,
  canActivate: [AuthGuardService],
  data: {
    title: 'GeoDatabase',
  }
},
{
  path: 'msi-config',
  component: MsiConfigComponent,
  canActivate: [AuthGuardService],
  data: {
    title: 'MSI Configuration',
  }
},
{
  path: 'auth_error',
  component: AuthErrorComponent,
  data: {
    title: 'Error',
  }
},
{
  path: 'access_error',
  component: AccessErrorComponent,
  data: {
    title: 'Error',
  }
},
{
  path: 'boundary',
  component: BoundaryComponent,
  canActivate: [AuthGuardService],
  data: {
    title: 'Boundary'
  }
},
{
  path: 'boundary/gov',
  component: BoundaryComponent,
  canActivate: [AuthGuardService],
  data: {
    title: 'Boundary'
  }
},

{
  path: 'external-edit',
  component: EditExternalTenantComponent,
  canActivate: [AuthGuardService],
  data: {
    title: 'Edit Tenant Id',
  }
},
{
  path: 'location-verify-mapping',
  component: LocationVerifyMappingComponent,
  canActivate: [AuthGuardService],
  data: {
    title: 'LocationVerify',
  }
},

{
  path: 'edit-tenant-id',
  component: EditTenantIDComponent,
  canActivate: [AuthGuardService],
  data: {
    title: 'Edit Tenant Id',
  }
},
{
    path: '',
    component: AppComponent,
    canActivate: [AuthGuardService]
},
{
  path: 'boundary-edit-tenant-id',
  component: BoundaryEditTenantIDComponent,
  canActivate: [AuthGuardService],
  data: {
    title: 'Edit Tenant Id',
  }
}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

