import { Component, OnInit } from '@angular/core';
import { RestService } from '../services/rest.service';
import { Router } from '@angular/router';
import { ToastService } from '@msi/cobalt';
import { error } from 'console';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import {CcadminHelperService } from '../services/ccadmin-helper.service';

@Component({
  selector: 'app-edit-tenant-id',
  templateUrl: './edit-tenant-id.component.html',
})
export class EditTenantIDComponent implements OnInit {

  TenantId;
  custIdselect = new FormControl();
  TenantId1: string;
  isNewTenantId = true;
  customerIddropdown;
  isExistingTenant;
  custTenantId: string;
  CanSetTenantId = false;
  constructor(public rest: RestService,
              private toastService: ToastService, private ccadmin: CcadminHelperService ) { }

  async ngOnInit() {
    this.isExistingTenant = false;
    document.getElementById('anotherCust').style.display = 'none';
    this.rest.getMe().subscribe(data => {
      this.ccadmin.CCAdminIsImpersonating(data);
      this.CanSetTenantId = this.ccadmin.IsSuperAdmin;
      if (!this.CanSetTenantId) {
          this.toastService.error(`You do not have valid permissions to edit Tenant ID. You must be an MSI Super-Admin.`);
      }
      this.rest.getCustomerList('Address').subscribe(x => {
        this.customerIddropdown = x;
        this.customerIddropdown.sort();
      });
      this.rest.agencyID = data._links.agency.agencyId;
      this.rest.customerID = data._links.customer.customerId;
      console.log(this.rest);
      this.rest.gettenantId('Address').subscribe(x => {
        if (x === '') {
          this.toastService.warning(`Customer does not have a stored TenantId, see suggested TenantId`);
          this.TenantId1 = this.rest.customerID;
          const re = /\./gi;
          this.TenantId1 = this.TenantId1.toLowerCase().replace(re, '-');
          this.TenantId = this.TenantId1;
        }
        else {
        this.isNewTenantId = false;
        this.TenantId = x;
        }
      },
      (e: HttpErrorResponse) => {
        if (e.status === 404) {
          this.toastService.warning(`Customer does not have a stored TenantId, see suggested TenantId`);
          this.TenantId1 = this.rest.customerID;
          const re = /\./gi;
          this.TenantId1 = this.TenantId1.toLowerCase().replace(re, '-');
          this.TenantId = this.TenantId1;
        } else {
        this.toastService.error(`Failed to get Customer TenantId.`);
        }
      });
    });


  }


  public validatetenantId() {
if (this.isExistingTenant) {

const tenantId = ((document.getElementById('TenantIdInput') as HTMLInputElement).value);
this.rest.getCustomerTenantId(this.custIdselect.value, 'Address').subscribe(tenant => {
    this.custTenantId = 'TenantId: ' + tenant;
  });
}
  }

  UseExistingTenant() {
    this.toastService.warning('​Data Privacy Warning!! Using another customer\'s tenantId should only be allowed for agencies of the same customer!');
    document.getElementById('anotherCust').style.display = 'block';
    document.getElementById('uniquetenant').style.display = 'none';
    this.isExistingTenant = true;
  }
  UseUniqueTenant() {
    this.isExistingTenant = false;
    document.getElementById('anotherCust').style.display = 'none';
    document.getElementById('uniquetenant').style.display = 'block';
  }

  SetTenantId() {
    const tenantId = ((document.getElementById('TenantIdInput') as HTMLInputElement).value);
    if (tenantId.match(/^[0-9a-z](?!.*-{2})[0-9a-z-]{1,61}[0-9a-z]$/)) {
    if (this.isNewTenantId === true ) {
      const body = {
        CustomerId: this.rest.customerID,
        isExistingTenant: (this.TenantId === tenantId),
        TenantId: tenantId,
        serviceName: 'Address'

      };
      this.rest.post_addcustomertenant(body).subscribe(x => {
      this.toastService.success(`Customer TenantId set as ${tenantId}.`);
    },
    (e: HttpErrorResponse) => {
      this.toastService.error(`Failed to set Customer TenantId  as ${tenantId}..`);
    }
    );
  } else {
    const body = {
      CustomerId: this.rest.customerID,
      isExistingTenant: (this.TenantId === tenantId),
      TenantId: tenantId,
      serviceName: 'Address'
    };
    this.rest.post_updatecustomertenant(body).subscribe(x => {
      this.toastService.success(`Customer TenantId updated to ${tenantId}.`);
    },
    (e: HttpErrorResponse) => {
      this.toastService.error(`Failed to update Customer TenantId to ${tenantId} due to error: ${e.error}`);
    }
    );
  }
} else {
  let tenantIdtext = 'TenantId must be between 3 & 63 characters, contain only lowercase letters';
  tenantIdtext = tenantIdtext + 'numbers or a - and start and end with a letter or number.';
  this.toastService.error(tenantIdtext); }

  }
  SetOtherCustomersTenantId() {
    const cid = this.custIdselect.value;
    this.rest.getCustomerTenantId(cid, 'Address').subscribe(x => {
      if (this.isNewTenantId === true ) {
        const body = {
          CustomerId: this.rest.customerID,
          isExistingTenant: true,
          TenantId: x.toString(),
          serviceName: 'Address'

        };
        this.rest.post_addcustomertenant(body).subscribe(y => {
        this.toastService.success(`Customer TenantId set as ${x}.`);
      },
      (e: HttpErrorResponse) => {
        this.toastService.error(`Failed to update Customer TenantId due to error: ${e.error}`);
      }
      );
    } else {
      const body = {
        CustomerId: this.rest.customerID,
        isExistingTenant: true,
        TenantId: x.toString(),
        serviceName: 'Address'
      };
      this.rest.post_updatecustomertenant(body).subscribe(y => {
        this.toastService.success(`Customer TenantId set as ${x}.`);

      },
      (e: HttpErrorResponse) => {
        this.toastService.error(`Failed to set Customer TenantId set as ${x} due to error: ${e.error}.`);
      }
      );
    }
    },
    (e: HttpErrorResponse) => {
      if (e.status === 404) {
        this.toastService.error(`Entered CustomerID does not have a tenantId.`);
      } else {
      this.toastService.error(`Unable to retrieve Customer TenantId.`);
      }
    }
    );

  }

}
