import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { TokenService } from '../services/token.service';
import { mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { EnvService } from '../services/env.service';
import { HeaderTokenService } from '@msi/commandcentral-user-authentication';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  localToken: string;
  env = this.envService.env1;
  env1 = this.envService.env;
  env2 = this.envService.env2;
  domainExtension = this.envService.domainExtension;
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (
      req.url.match(`https://admin-api${this.env1}commandcentral.${this.domainExtension}/api/admin`) ||
      req.url.match(`https://coregis${this.env}commandcentral.${this.domainExtension}/`) ||
      req.url.match(`https://admin-api${this.env1}commandcentral.${this.domainExtension}/api/admin`) ||
      req.url.match(`https://coregis${this.env}commandcentral.${this.domainExtension}/`) ||
      req.url.match(`https://coregis.usgov-stage.commandcentral.${this.domainExtension}/`) ||
      req.url.match(`https://coregis.stage.commandcentral.${this.domainExtension}/`) ||
      req.url.match(`https://coregis.dev.commandcentral.${this.domainExtension}/`)
    ) {
      if (this.tokenService.retrieveToken() == null) {
        return this.headerTokenService.tokenReady.pipe(
          switchMap((token) => {
            const jwttoken = this.headerTokenService.getTokenSync();
            this.tokenService.setToken(jwttoken);
            req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + jwttoken) });
            return next.handle(req);
          })
        );
      } else {
        req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + this.tokenService.retrieveToken()) });
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }

  constructor(
    private tokenService: TokenService,
    private headerTokenService: HeaderTokenService,
    private envService: EnvService
  ) {}
}
