import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RestService } from '../services/rest.service';
import { ToastService, FileUploadComponent, States, AssetStatus, CaseService, FileUploadService, FileUploadManager } from '@msi/cobalt';
import { BlobStorageService } from '../services/blob-storage.service';
import { BlobUploadsViewStateService } from '../services/blob-uploads-view-state.service';
import { BlobDeletesViewStateService } from '../services/blob-deletes-view-state.service';
import {CoreGISFileUploadManager} from '../services/file-upload-manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import {CcadminHelperService } from '../services/ccadmin-helper.service';
import { UploadTypeHelperService } from '../upload-footer/upload-type-helper.service';
import { EnvService } from '../services/env.service';

@Component({
  selector: 'app-geodatabase',
  templateUrl: './geodatabase.component.html',
  providers: [RestService, BlobStorageService, BlobUploadsViewStateService, BlobDeletesViewStateService, CcadminHelperService]

})
export class GeodatabaseComponent implements OnInit, AfterViewInit {
  constructor(public rest: RestService,
              private toastService: ToastService,
              public blobState: BlobUploadsViewStateService,
              private blobDeleteState: BlobDeletesViewStateService,
              private fileUploadService: FileUploadService,
              public ccadmin: CcadminHelperService,
              private footerhelper: UploadTypeHelperService,
              private env: EnvService
              ) {
                this.uploadmanager = new CoreGISFileUploadManager(this.fileUploadService.get(this.caseId), this.toastService);
               }

  @ViewChild(FileUploadComponent, {static: true}) upload: FileUploadComponent;
  private CCAdminVersion = this.env.admin;
  env1 = this.env.env1;

  DBSelect = new FormControl();
  db1 = new FormControl();
  DBoptions: string[];
    fileInput: any;
  selectedingest;
  activegeodb;
  inActivegeodb;
  customTenantUpload = false;
  cloudDataSet;
  submitreturn;
  choosenupload;
  caseId = 'coreGIS';
  uploadmanager;
  n;
  fileCounter;
  filescanned;
  status;
  notAdmin = true;
  filesUploaded = true;
  filesToUpload: FileList;
  filesToScan: string[] = [];
  filesToDelete: string[] = [];
  fileSASURLs: string[];
  dataSetName: string;
  isimperson;
  ingestType = 'Addressing';
  uploadToExternalDB = false;
  upload_new = true;
  message='';
  async ngOnInit() {
    // await this.ccadmin.GetTenants();
    document.getElementById('activeGeoDropDown').style.display = 'none';
    document.getElementById('edittenantbutton-1').style.display = 'none';
    document.getElementById('dataSetDropdown').style.display = 'none';
    this.footerhelper.filetype$.subscribe((uploadtype => {
      this.choosenupload = uploadtype;
    }));
    this.getpermission();
  }
  ngAfterViewInit() {
    const uploadManager = this.uploadmanager;
    const caseService: CaseService = this.fileUploadService.get(this.caseId);
    //  upload progress
    const timestep = 5; // seconds
    caseService.on('callMethod', (method) => {
      if (method !== 'upload') { return; }
      const validupload = this.setTenantId();
      if (!validupload) {
          uploadManager.assets.forEach((asset) => {
            asset.status = AssetStatus.NOT_UPLOADED;
            asset.state = States.ERROR;
          });
          return; }
      this.filescanned = 0;
      this.fileCounter = uploadManager.assets.length;
      if (this.fileCounter === 0) {
        this.toastService.error(`No files found to be uploaded.`, undefined, {
          autoDismiss: (true && 5000)
        });
      } else {
      this.uploadSpecificFilesToActivity(uploadManager.assets);
      uploadManager.assets.forEach((asset) => {
        asset.status = 'UPLOADING';
        asset.progress = 0;
        asset.interval = setInterval(() => {
          if (asset.state !== States.IN_PROGRESS) { return; }
          if (asset.progress === 100) {
            clearInterval(asset.interval);
            this.filescanned++;
            if (this.fileCounter === this.filescanned) {
              // scan filelist for viruses
              this.retrieveDataSets();
              this.rest.get_filesasurl(this.filesToScan, this.blobState.blobState.GetService()).subscribe(data => {
            this.fileSASURLs = data;
            if (this.fileSASURLs !== undefined && this.fileSASURLs.length > 0) {
              const body = {
                sASTokenFileURLs: this.fileSASURLs
             };
              this.rest.checkfilesInBlob(body).subscribe(filesData => {
              this.rest.scanfile(body).subscribe(scandata => {
                const scanResult = scandata;
                let i = 0;
                if (scanResult.body !== undefined) {
                  const fileNameKey = 'filename';
                  const scanstatusKey = 'scanstatus';
                  while (i < scanResult.body.length) {
                    if (scanResult.body[i][scanstatusKey] !== 'clean') {
                      this.toastService.error(`File '${scanResult.body[i][fileNameKey]}' is infected, will be deleted.`);
                      console.log(`Deleting file '${scanResult.body[i][fileNameKey]}'`);
                      this.blobDeleteState.deleteItem(scanResult.body[i][fileNameKey]);
                    }
                    const fileScannedName = scanResult.body[i][fileNameKey];
                    uploadManager.assets.forEach(a => {
                      if (fileScannedName.endsWith(a.name)) {
                        if (scanResult.body[i][scanstatusKey] !== 'clean') {
                          a.status = AssetStatus.NOT_UPLOADED;
                          a.state = States.ERROR;
                        } else {
                          if (this.notAdmin === false) {
                            this.filesUploaded = true;
                          }
                          a.status = AssetStatus.VERIFIED;
                          a.state = States.DONE;
                        }
                      }
                    });
                    i++;
                  }
                }
                else
                {
                  this.toastService.error(`Virus scan failed. Deleting all files.`);
                  for (const file of this.filesToScan)
                  {
                    this.blobDeleteState.deleteItem(file);
                  }
                  uploadManager.assets.forEach(a => {
                        a.status = AssetStatus.NOT_UPLOADED;
                        a.state = States.ERROR;
                  });
                }
              },
              (err: HttpErrorResponse) => {
                console.log(err);
                this.toastService.error(`Virus scan failed : ${err.message}. Deleting all files.`);
                for (const file of this.filesToScan)
                {
                  this.blobDeleteState.deleteItem(file);
                }
                uploadManager.assets.forEach(a => {
                      a.status = AssetStatus.NOT_UPLOADED;
                      a.state = States.ERROR;
                });
              });
             },
              (err: HttpErrorResponse) => {
                console.log(err);
                this.toastService.error(`Failed to upload all files : ${err.message}. Deleting remaining files.`);
                for (const file of this.filesToScan)
                {
                  this.blobDeleteState.deleteItem(file);
                }
                uploadManager.assets.forEach(a => {
                      a.status = AssetStatus.NOT_UPLOADED;
                      a.state = States.ERROR;
                });
              });
            }
          });
            }
          }
        }, timestep * 5000);
      });
      this.deleteFileToActivity();
    }
    });
 
  }
    /* istanbul ignore next */

  uploadSpecificFilesToActivity(assetlist): void {
    this.blobState.uploadedItems$
    .subscribe(
      x => {
         this.n = 0;
         x.forEach(e => {
          assetlist.forEach(a => {
            if (e.filename === a.files[0].name && !this.filesToScan.includes(e.datasetName + '/' + e.filename)) {
              a.progress = e.progress;
              if (e.progress === 100) {
              a.status = AssetStatus.VERIFYING;
              this.filesToScan.push(e.datasetName + '/' + e.filename);
            }
          }
          });
        });
  },
      (err: Error) => {
       this.toastService.error('Error uploading data set to storage');
       this.blobState.setUploadStatus('Upload failed.');
       console.error('Error while uploading files to blob: ' + err);
      },
      () => {
      }
    );

    this.filesUploaded = false;
    assetlist.forEach(a => {
      this.blobState.uploadItems(a.files);
    });
  }

  deleteFileToActivity(): void {
      this.blobDeleteState.deletedItems$
      .subscribe(
        x => {
    },
        (err: Error) => {
        this.toastService.error('Error deleting file from dataset.');
        console.log('Error while deleting files from blob: ' + err);
        },
        () => {
        }
      );
}

  setTenantId(): boolean {
    if (this.uploadToExternalDB) {
      return true;
    }
    if (this.ccadmin.HasAddress && this.ccadmin.HasBoundary) {
      // if they are the same we are uploading to same database (default tenantId)
      if (!this.ccadmin.SameTenants()) {
        switch (this.choosenupload) {
          case 'Boundary':
            this.blobDeleteState.blobState.setService('Boundary');
            this.blobState.blobState.setService('Boundary');
            this.customTenantUpload = true;
            return true;
          case 'Address':
            this.blobDeleteState.blobState.setService('Address');
            this.blobState.blobState.setService('Address');
            this.customTenantUpload = false;
            return true;
          default:
            this.toastService.error(`Choose a type of file to upload from the dropdown.`);
            return false;
          }
      }
      else{
        this.blobDeleteState.blobState.setService('Address');
        this.blobState.blobState.setService('Address');
        this.customTenantUpload = false;
        return true;
      }
    }
    else if (this.ccadmin.HasBoundary) {
      this.blobDeleteState.blobState.setService('Boundary');
      this.blobState.blobState.setService('Boundary');
      this.customTenantUpload = true;
      return true;
    } else if (this.ccadmin.HasAddress) {
      this.customTenantUpload = false;
      this.blobDeleteState.blobState.setService('Address');
      this.blobState.blobState.setService('Address');
      return true;
    }
    this.toastService.error(`Either Enable GeoVerification or Boundary Service or turn on "external customer" setting.`);
    return false;
  }

  private async getpermission() {
    let IngestName;
    if (this.env1 === '.dev.') {
      IngestName = 'GeoDataIngestDev';
    }
    else if (this.env1 === '.usgov-stage.') {
      IngestName = 'GeoDataIngestUSGov';
    }
    else {
      IngestName = 'GeoDataIngest';
    }
    if (this.CCAdminVersion === 'admin2.') {
      this.rest.getMe2().subscribe(data => {
        data.me.permissions.forEach(element => {
          if (element.serviceSystemName === IngestName && element.permissionName === 'Ingest') {
            this.notAdmin = false;
          }
        });
        if (data.me.currentAgency === undefined)
          {
            const val = sessionStorage.getItem('customerID');
            this.rest.agencyID = val;
            this.rest.customerID = val;
          }
          else
          {
            this.rest.agencyID = data.me.currentAgency;
            this.rest.customerID = data.me.currentAgency;
          }
         

        this.rest.get_agencyschema_2().subscribe(async data1 => {
          this.ccadmin.ParseServices(data1);
          if (this.env1 === '.usgov-stage.') {
            this.ccadmin.HasAddress = false;
          }
          this.rest.get_geoDatabaseIngestConfiguration().subscribe(async data => {
            this.uploadToExternalDB = data.UseExternalDatabase; 
            this.message = this.uploadToExternalDB ? 'This dataset will be applied to PremierOne.' : '';
            await this.loadData();
           },
           async (err) => {
             await this.loadData();   
           });
        });
      });

    }
    else {
    this.rest.getMe().subscribe(data => {
      if (data.services[IngestName]) {
        if (data.services[IngestName].permissions ) {
          if (data.services[IngestName].permissions.find(i => i.systemName === 'IngestAdmin') !== undefined) {
            this.notAdmin = false;
          }
        }
      }
      else {
        this.toastService.error('UserGroup does not have Ingest Service Capability');
      }
      this.ccadmin.CCAdminIsImpersonating(data);
      this.isimperson = this.ccadmin.IsSuperAdmin;
      this.rest.agencyID = data._links.agency.agencyId;
      this.rest.customerID = data._links.customer.customerId;
      this.rest.get_agencyschema().subscribe(async data1 => {
        this.ccadmin.ParseServices(data1);
        if (this.env1 === '.usgov-stage.') {
       this.ccadmin.HasAddress = false;
     }
     this.rest.get_geoDatabaseIngestConfiguration().subscribe(async data => {
      console.log(data);
      this.uploadToExternalDB = data.UseExternalDatabase; 
      this.message = this.uploadToExternalDB ? 'This dataset will be applied to PremierOne.' : '';
        await this.loadData();       
      },
      async (err) => {
        await this.loadData();   
      });
      });
      });
    }
  }

  private async loadData() {
    this.ccadmin.UseExternalDb = this.uploadToExternalDB;
    await this.ccadmin.GetTenants();
    if (this.ccadmin.UseExternalDb && this.ccadmin.ExternalTenant === undefined) {
      this.toastService.error('The External Ingest Service has not been configured for this customer. Contact the system administrator.');
    }
    if (this.check_for_validity()) {
      this.formatcustomer();
      if(!this.upload_new)
      {
        this.retrieveDataSets();
      }
    }
    else {
      this.notAdmin = true;
    }
  }
  
  check_for_validity(): boolean {
    let valid = true;
    if (!this.ccadmin.HasAddress && !this.ccadmin.HasBoundary && !this.uploadToExternalDB) {
      this.toastService.error(`Neither GeoVerification or Boundary service is enabled and properly configured. Enable either service or turn on "external" customer.`);
      valid = false;

    }
    return valid;
  }
  radiochange(e) {
    if (e.value === 'Boundary') {
      this.ingestType = 'Boundary';
      document.getElementById('activeGeoText').style.display = 'none';
      document.getElementById('activeGeoDropDown').style.display = 'none';
      if (this.uploadToExternalDB) {
        document.getElementById('activeGeoDropDown').style.display = 'block';
      }
      if(!this.upload_new) //If re-using DB, get data sets.
      {
        this.retrieveDataSets();
      }
    }
    else if (e.value === 'Address') {
      this.ingestType = 'Addressing';
      if(!this.upload_new) //If re-using DB, get data sets.
      {
        this.retrieveDataSets();
      }
      if (!this.uploadToExternalDB) {document.getElementById('activeGeoText').style.display = 'block'; }
      else {document.getElementById('activeGeoDropDown').style.display = 'block'; }
    }
  }
  uploadOrReuseDBChange(e) {
    if (e.value === 'ReuseDB') {
      document.getElementById('uploaddiv').style.display = 'none';
      document.getElementById('dataSetDropdown').style.display = 'block';
      this.upload_new = false;
      this.retrieveDataSets();
    }
    else if (e.value === 'UploadNewDB') {
      document.getElementById('uploaddiv').style.display = 'block';
      document.getElementById('dataSetDropdown').style.display = 'none';
      this.upload_new = true;
    }
  }
   formatcustomer() {
     document.getElementById('Dropdown-Upload-Type').style.display = 'block';
     if (this.uploadToExternalDB) {
       // if customer is external use formatting for external customers
          document.getElementById('activeGeoDropDown').style.display = 'block';
          document.getElementById('activeGeoText').style.display = 'none';
          document.getElementById('IngestType').style.display = 'block';
          document.getElementById('Dropdown-Upload-Type').style.display = 'none';
          this.blobDeleteState.blobState.setService('External');
          this.blobState.blobState.setService('External');
          if (this.isimperson) {
            document.getElementById('edittenantbutton-1').style.display = 'block';
          }
          return;
        }
     else if (this.ccadmin.HasAddress && this.ccadmin.HasBoundary) {
      this.getActiveGeoDb();
      this.ingestType = 'Addressing';
      if (this.ccadmin.GetBoundaryTenant() === this.ccadmin.GetAddressTenant()) {
        // tenant IDs are the same
        document.getElementById('Dropdown-Upload-Type').style.display = 'none';
      }
     }
     else if (this.ccadmin.HasAddress) {
      this.getActiveGeoDb();
      document.getElementById('IngestType').style.display = 'none';
      document.getElementById('Dropdown-Upload-Type').style.display = 'none';
      document.getElementById('activeGeoDropDown').style.display = 'none';
      document.getElementById('activeGeoText').style.display = 'block';
      this.ingestType = 'Addressing';
     }
     else if (this.ccadmin.HasBoundary) {
      this.blobDeleteState.blobState.setService('Boundary');
      this.blobState.blobState.setService('Boundary');
      document.getElementById('activeGeoText').style.display = 'none';
      document.getElementById('IngestType').style.display = 'none';
      document.getElementById('activeGeoDropDown').style.display = 'none';
      document.getElementById('Dropdown-Upload-Type').style.display = 'none';
      this.ingestType = 'Boundary';
     }
   }
   private getActiveGeoDb() {
    this.rest.get_ActiveDB(this.rest.customerID)
    .subscribe(data => {
        console.log(data);
        this.activegeodb = data;
        this.inActivegeodb = (this.activegeodb === 'GeoDB_A' ? 'GeoDB_B' : 'GeoDB_A');
      },
      (err) => {
        this.toastService.error(
          `Failed to get active geo database for customer: ${err.error}.`
        );
      }
    );
    }
   refresh() {
    if (this.notAdmin === false) {
      this.rest.get_dbRefresh(this.submitreturn).subscribe(data => {
        this.status = data.status;
        if (data.status === 'Failed') {
          this.toastService.error(`Pipeline with RunId ${data.runId} failed after ${data.durationInMs} ms with message ${data.message}`);
        } else if (data.status === 'Succeeded') {
          this.toastService.success(`Pipeline with RunId ${data.runId} Succeeded in ${data.durationInMs} ms.`, undefined, {
            autoDismiss: (true && 5000)}
            );
        }
        else if (data.status === 'InProgress') {
          this.toastService.success(`Pipeline with RunId ${data.runId} is in progress`, undefined, {
            autoDismiss: (true && 5000)});
        }
      });
    }}

  submitbutton() {
    let godbObj = {
      UseExternalDatabase: this.uploadToExternalDB
  }
  const date = new Date(Date.now());
  var datasetName = `data-${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
this.rest.updateGeoDatabaseIngestConfiguration(godbObj).subscribe(
  (res) => {
    if (res.status === 200) {
      this.refresh();
    } else {
      this.toastService.error('DataSet Submission failed.');
    }
  },
  (err: HttpErrorResponse) => {
    console.log(err);
    this.toastService.error(`DataSet Submission failed with error: ${err.error}`);
  });
    if (this.uploadToExternalDB) {
    const body = {
      activeDataSet: this.db1.value,
      tenantId:  this.ccadmin.GetIngestTenant(),
      cloudDataSet: (this.DBSelect === undefined || this.DBSelect.value == null) ? datasetName : this.DBSelect.value,
      IngestType: this.ingestType
   };
    this.rest.put_applyextdb(body).subscribe(
      (res) => {
        if (res.status === 201) {
          this.toastService.success('The submitted data is set to GeoDatabase!', undefined, {
            autoDismiss: (true && 5000)});
          this.submitreturn = res.body;
          this.refresh();
        } else {
          this.toastService.error('DataSet Submission failed.');
          this.submitreturn = res.body;
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.toastService.error(`DataSet Submission failed with error: ${err.error}`);
      });
  } else {
    let body;
    if (this.ingestType === 'Boundary') {
       body = {
        tenantId:  this.ccadmin.GetBoundaryTenant(),
        cloudDataSet: (this.DBSelect === undefined || this.DBSelect.value == null) ? datasetName : this.DBSelect.value,
        IngestType: this.ingestType
     };    }
     else {
       this.getActiveGeoDb();
       body = {
      activeDataSet: this.activegeodb,
      tenantId:  this.ccadmin.GetAddressTenant(),
      cloudDataSet: (this.DBSelect === undefined || this.DBSelect.value == null) ? datasetName : this.DBSelect.value,
      IngestType: this.ingestType
   };
  }
    this.rest.put_applydb(body).subscribe(
      (res) => {
        console.log(res);
        if (res.status === 201) {
          console.log(res);
          this.toastService.success('The submitted data is set to GeoDatabase!', undefined, {
            autoDismiss: (true && 5000)});
          this.submitreturn = res.body;
          this.refresh();
        } else {
          console.log(res);
          this.toastService.error('DataSet Submission failed.');
          this.submitreturn = res.body;
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.toastService.error(`DataSet Submission failed with error: ${err.error}`);
      });
    }}

  private retrieveDataSets() {
    if (!this.uploadToExternalDB) {

        this.rest.get_dbList(this.ingestType).subscribe(data => {
      this.DBoptions = data;
    });
  } else {
    this.rest.get_DbListByTenant().subscribe(options => {
      this.DBoptions = options;
    });
  }
}
}