import { Injectable } from '@angular/core';
import { EnvService, EnvType } from './env.service';
import { fromEvent, Observable, of } from 'rxjs';
import { map, timeout, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private env: string;
  private TOKEN_KEY = 'JWT_TOKEN';
  private admin;

  retrieveToken(): string {
    return sessionStorage.getItem(this.TOKEN_KEY);
      }
      setToken(data) {
        sessionStorage.setItem(this.TOKEN_KEY, data);
      }

  public setAdminUrl(url: string) {
    this.admin = url;
  }
  private GetParentURL(): string {
    let PARENT_URL;
    if (this.admin !== undefined) {
      PARENT_URL = `https://${this.admin}${this.env}commandcentral.com`;
    }
    else {
    PARENT_URL = document.referrer;
    PARENT_URL = PARENT_URL.slice(0, -1);
    }
    return PARENT_URL;
  }

}
