import {Component, OnInit} from '@angular/core';
import {HeaderTokenService} from '@msi/commandcentral-user-authentication';
import {take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {TokenService} from './services/token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'AdminUI';
  user;
  clientId;
  customer;
  agency;
  scopes;
  customerId;
  roleSystemName;
  groupSystemName;
  agencyId;
  token;
  admin;
  cloud;

  constructor(
    private headerTokenService: HeaderTokenService,
    private route: ActivatedRoute,
    private storedToken: TokenService,
) {
}

  ngOnInit() {
    sessionStorage.removeItem('JWT_TOKEN');
    this.headerTokenService.tokenReady.pipe(take(1)).subscribe(async isTokenReady => {
      if (isTokenReady) {
        const token = this.headerTokenService.getTokenSync();
        sessionStorage.setItem('JWT_TOKEN', token);
      }
    });
    this.route.queryParams.subscribe((params) => {
        this.customerId = params.customerId;
        this.roleSystemName = params.roleSystemName;
        this.groupSystemName = params.groupSystemName;
        this.agencyId = params.agencyId;
        this.admin = params.admin;
        // this.cloud = params.cloud;
        // sessionStorage.setItem('cloud', this.cloud);
    });

    // to get tokenReady value, confirmAuthGuardService must be added to routes, see app-routing.module.ts
    // this.headerTokenService.tokenReady.pipe(take(1))
    //     .subscribe(isTokenReady => {
    //         if (isTokenReady) {
    //             const texttoken = this.headerTokenService.getTokenSync();
    //             this.token = texttoken;
    //             this.storedToken.setToken(this.token);
    //         }
    //     });
}
}
