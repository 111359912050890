import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { MsiCommonModule } from '@msi/cobalt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationVerifyMappingComponent } from './location-verify-mapping/location-verify-mapping.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './interceptors/auth-interceptor.service';
import { AuthErrorComponent } from './components/auth-error/auth-error.component';
import { AccessErrorComponent } from './components/access-error/access-error.component';
import { GeodatabaseComponent } from './geodatabase/geodatabase.component';
import { Router } from '@angular/router';
import { AppRoutingModule } from './app.routes';
import { EditTenantIDComponent } from './edit-tenant-id/edit-tenant-id.component';
import { UploadFooterComponent } from './upload-footer/upload-footer.component';
import { EditExternalTenantComponent } from './edit-external-tenant/edit-external-tenant.component';
import { BoundaryComponent } from './boundary/boundary.component';
import { BoundaryEditTenantIDComponent } from './boundary-edit-tenant-id/boundary-edit-tenant-id.component';
import {IamAuthGrantConfigToken} from '@msi/commandcentral-user-authentication';
import {getAppSpecificIamConfig} from './config/app.config';
import { UserAuthenticationModule } from '@msi/commandcentral-user-authentication';
import { MsiConfigComponent } from './msi-config/msi-config.component';
import { TranslocoRootModule } from './transloco-root.module';


@NgModule({
  declarations: [
    AppComponent,
    LocationVerifyMappingComponent,
    AuthErrorComponent,
    GeodatabaseComponent,
    AccessErrorComponent,
    EditTenantIDComponent,
    UploadFooterComponent,
    EditExternalTenantComponent,
    BoundaryComponent,
    BoundaryEditTenantIDComponent,
    MsiConfigComponent
    ],
  exports: [AppComponent, LocationVerifyMappingComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule ,
    MsiCommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    UserAuthenticationModule,
    TranslocoRootModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true},
    {
      provide: IamAuthGrantConfigToken,
      useFactory: getAppSpecificIamConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(router: Router) {
   }
 }
