import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RestService } from '../services/rest.service';
import { ToastService } from '@msi/cobalt';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CcadminHelperService } from '../services/ccadmin-helper.service';
import { EnvService } from '../services/env.service';

@Component({
  selector: 'app-boundary',
  templateUrl: './boundary.component.html',
  styleUrls: ['./boundary.component.scss'],
})
export class BoundaryComponent implements OnInit {
  constructor(
    public rest: RestService,
    private toastService: ToastService,
    private ccadmin: CcadminHelperService,
    private envService: EnvService
  ) {}
  BoundaryForm: FormGroup;
  notAdmin = true;
  env2 = this.envService.env2;
  env1 = this.envService.env1;
  domainExtension = this.envService.domainExtension;
  provision = false;
  private CCAdminVersion = this.envService.admin;
  boundarySchema: any;
  ngOnInit(): void {
    this.rest.UpdateUrl();
    this.getPermissions();
  }
  /* istanbul ignore next */

  public NavigateToBoundaryDraw(): void {
    const version = this.CCAdminVersion.slice(0, -1);
    let url = `https://boundary-admin${this.env2}commandcentral.${this.domainExtension}/gisboundary?admin=${version}`;
    if (this.env1 === '.usgov-stage.' || (this.env1 === '.stage.' && sessionStorage.getItem('cloud') === 'gov')) {
      url += '&cloud=gov';
    }
    window.open(url, '_self');
  }
  private getCCAdmin() {
    if (this.CCAdminVersion === 'admin2.') {
      this.rest.get_agencyschema_2().subscribe(
        (data) => {
          this.ccadmin.ParseServices(data);
        });
      }
    this.rest.getBoundaryConfiguration(this.rest.customerID, this.rest.agencyID).subscribe(
        (result) => {
         // this.ccadmin.ParseServices(data);
          if (result == null) {
            this.toastService.error('Boundary Service schema is not configured properly!');
          } else {
            this.boundarySchema = result;
            this.setFormValues();
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.toastService.error(
            `Unable to load Location Verification Data due to error ${err.message} with code ${err.status} ${err.statusText}.`
          );
        }
      );
    this.BoundaryForm = new FormGroup({
      Direction: new FormControl({ value: '', disabled: this.notAdmin }, Validators.required),
      DistanceInMeter: new FormControl({ value: '', disabled: this.notAdmin }, [
        Validators.required,
        Validators.min(0),
      ]),
      DistanceUnitOfMeasure: new FormControl({ value: '', disabled: this.notAdmin }, Validators.required),
      IsFilterBeatsByCityEnabled: new FormControl({ value: '', disabled: this.notAdmin }, Validators.required),
      IsActive:  new FormControl({ value: '', disabled: this.notAdmin }, Validators.required),
    });
  }
  public setFormValues() {
    let config;
    config = this.boundarySchema;
    this.BoundaryForm.setValue({
      Direction: config.Direction,
      DistanceInMeter: config.DistanceInMeter,
      DistanceUnitOfMeasure: config.DistanceUnitOfMeasure,
      IsFilterBeatsByCityEnabled: config.IsFilterBeatsByCityEnabled,
      IsActive: config.IsActive,
    });
  }
  private getPermissions() {
    let BoundaryName;
    const cloudversion = sessionStorage.getItem('cloud');
    if (this.env1 === '.dev.') {
      BoundaryName = 'GisBoundaryConfigDev';
    } else if (this.env1 === '.usgov-stage.' || cloudversion === 'gov') {
      BoundaryName = 'GisBoundaryConfigUSGov';
    } else {
      BoundaryName = 'GisBoundaryConfig';
    }
    if (this.CCAdminVersion === 'admin2.') {
      this.rest.getMe2().subscribe((data) => {
        data.me.permissions.forEach((element) => {
          if (element.serviceSystemName === BoundaryName && element.permissionName === 'Edit Settings') {
            this.notAdmin = false;
          } else if (element.serviceSystemName === BoundaryName && element.permissionName === 'Provision') {
            this.provision = true;
          }
        });
        if (data.me.currentAgency === undefined)
        {
          const val = sessionStorage.getItem('customerID');
          this.rest.agencyID = val;
          this.rest.customerID = val;
        }
        else
        {
      this.rest.agencyID = data.me.currentAgency;
      this.rest.customerID = data.me.currentAgency;
        }
        this.getCCAdmin();
      });
    } else {
      this.rest.getMe().subscribe((data) => {
        if (data.services[BoundaryName]) {
          if (data.services[BoundaryName].permissions) {
            if (data.services[BoundaryName].permissions.find((i) => i.systemName === 'Edit_Settings') !== undefined) {
              this.notAdmin = false;
            }
            if (data.services[BoundaryName].permissions.find((i) => i.systemName === 'provision') !== undefined) {
              this.provision = true;
            }
          }
        } else {
          this.toastService.error('User group does not have Boundry Service Capability');
        }
        if (data.me.currentAgency === undefined)
        {
          const val = sessionStorage.getItem('customerID');
          this.rest.agencyID = val;
          this.rest.customerID = val;
        }
        else
        {
          this.rest.agencyID = data._links.agency.agencyId;
          this.rest.customerID = data._links.customer.customerId;
        }
        this.getCCAdmin();
      });
    }
  }

  public uploaddata() {
    let Schema;

    if (this.BoundaryForm.status === 'INVALID') {
      this.toastService.error('Please fix any data validation errors!');
      return;
    }
    const keylist = Object.keys(this.BoundaryForm.value);
    Schema = this.boundarySchema;
    keylist.forEach((key) => {
        Schema[key.toString()] = this.BoundaryForm.value[key.toString()];
      });
    this.rest.post_boundaryConfiguration(Schema).subscribe(
        (res) => {
          if (res.status === 200) {
            console.log(res);
            this.toastService.success('Data saved successfully!', undefined, {
              autoDismiss: true && 3000,
            });
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          if (err.status === 404) {
            // if returns a 404
            const key = 'id';
            delete this.ccadmin.BoundarySchema[key];
            const key1 = '_links';
            const key2 = 'self';
            delete this.ccadmin.BoundarySchema[key1][key2];
            this.rest.post_createagency(this.ccadmin.BoundarySchema).subscribe(
              (res) => {
                this.toastService.success('Data saved successfully for new agency.', undefined, {
                  autoDismiss: true && 5000,
                });
              },
              (err1: HttpErrorResponse) => {
                console.log(err1);
                this.toastService.error(
                  `Data submission failed error:  ${err1.message} with code  ${err1.status} ${err1.statusText} `
                );
              }
            );
          } else {
            this.toastService.error(
              `Data submission failed due to error:  ${err.message} with code  ${err.status} ${err.statusText} `
            );
          }
        }
      );
  }
}
