import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap, mergeMap, flatMap } from 'rxjs/operators';
import { TokenService } from './token.service';
import { EnvService, EnvType } from './env.service';
import { ActivatedRoute } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { BlobStorageRequest } from '../types/azure-storage';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  constructor(
    private http: HttpClient,
    private AG: AuthGuardService,
    private route: ActivatedRoute,
    private tokenService: TokenService,
    private envService: EnvService
  ) {}
  env1 = this.envService.env1;
  env = this.envService.env;
  domainExtension = this.envService.domainExtension;

  geobaseURL = `https://coregis${this.env1}commandcentral.${this.domainExtension}/admin/v1/`;
  baseUrl = `https://admin-api${this.env}commandcentral.${this.domainExtension}/api/admin/customers`;
  baseUrl2 = `https://admin-api${this.env}commandcentral.${this.domainExtension}/api/admin-v2`;
  getMeurl = `https://admin-api${this.env}commandcentral.${this.domainExtension}/api/admin/me`;
  sasTokenURL = `https://coregis${this.env1}commandcentral.${this.domainExtension}/sasgen/v1/sasgen`;
  customerID = this.AG.customerID;
  agencyID = this.AG.agencyId;
  // for running locally uncomment the 2 lines below and replacce with customerID and agencyId and comment out two lines above
  // customerID = 'msi.test.boundary';
  // agencyID = 'msi.test.boundary';

  title = 'AdminUI';

  // get customerschema
  public get_customerschema() {
    return this.http.get<any>(this.baseUrl + '/' + this.customerID + '/services?offset=0&limit=100&order=id');
  }
  public UpdateUrl() {
    if (this.env1 === '.stage.' && sessionStorage.getItem('cloud') === 'gov') {
      this.geobaseURL = this.geobaseURL.replace('.stage.', '.usgov-stage.');
    }
  }
  public getPHZTenatSize(tenantId) {
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(this.geobaseURL + 'Phz/tenant/' + tenantId, opt);
  }

  public GetServiceCustomers(service: string) {
    return this.http.get<any>(this.baseUrl2 + '/services/' + service + '/customer-configuration');
  }
  // Get agency schema
  // the token is inserted by auth-interceptor hence no need to get the token and create header explicitly here
  public get_agencyschema() {
    return this.http.get<any>(
      this.baseUrl + '/' + this.customerID + '/agencies/' + this.agencyID + '/services?offset=0&limit=-1&order=id'
    );
  }
  public get_agencyschema_2() {
    return this.http.get<any>(this.baseUrl2 + '/agencies/' + this.agencyID + '/services?limit=-1');
  }
  public get_agencyschemabyName(name: string) {
    return this.http.get<any>(
      this.baseUrl + '/' + this.customerID + '/agencies/' + name + '/services?offset=0&limit=-1&order=id'
    );
  }
  public getMe() {
    return this.http.get<any>(this.getMeurl);
  }

  public getMe2() {
    return this.http.get<any>(this.baseUrl2 + '/me?with-relations=ALL');
  }

  // used for geodatabase to call gisadmin api
  // the token is inserted by auth-interceptor hence no need to get the token and create header explicitly here

  public get_sasToken(serviceName: string) {
    return this.http.get<BlobStorageRequest>(this.sasTokenURL + '/' + serviceName);
  }

  public get_DbListByTenant() {
    return this.http.get<any>(this.geobaseURL + 'ingest/allDataSetsExternal/' + this.customerID);
  }

  public get_dbList(ingestType: string) {
    return this.http.get<any>(
      this.geobaseURL + 'ingest/allDataSets/' + this.customerID + '/' + this.agencyID + '/' + ingestType
    );
  }
  public get_dbRefresh(PipelineStatus) {
    return this.http.get<any>(this.geobaseURL + 'ingest/PipelineStatus/' + PipelineStatus);
  }

  public get_geoDatabaseIngestConfiguration() {
    return this.http.get<any>(this.geobaseURL + 'Config/' + this.customerID + '/' + this.agencyID + '/GeoDatabaseIngest');
  }

  public put_agencyschemabyName(name: string, ID: string, Obj) {
    const headers = new HttpHeaders({});
    return this.http.put(this.baseUrl + '/' + this.customerID + '/agencies/' + name + '/services/' + ID, Obj, {
      headers,
      observe: 'response',
    });
  }
  public put_agencyschema(ID: string, Obj) {
    const headers = new HttpHeaders({});
    return this.http.put(this.baseUrl + '/' + this.customerID + '/agencies/' + this.agencyID + '/services/' + ID, Obj, {
      headers,
      observe: 'response',
    });
  }

  public post_boundaryConfiguration(body: object) {
    return this.http.post(this.geobaseURL + 'Config/' + this.customerID + '/' + this.agencyID + '/BoundaryConfig', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),  observe: 'response'
    });
  }

  public put_agencyschema_2(service: string, Obj) {
    const headers = new HttpHeaders({});
    return this.http.put(this.baseUrl2 + '/agencies/' + this.agencyID + '/services/' + service, Obj, {
      headers,
      observe: 'response',
    });
  }
  public put_applydb(Obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    Obj.customerId = this.customerID;
    Obj.agencyId = this.agencyID;

    return this.http.post(this.geobaseURL + 'ingest/applyDataSet', Obj, {
      headers,
      observe: 'response',
      responseType: 'text',
    });
  }

  public updateGeoDatabaseIngestConfiguration(Obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    // Obj.customerId = this.customerID;
    // Obj.agencyId = this.agencyID;

    return this.http.post(this.geobaseURL + 'Config/' + this.customerID + '/' + this.agencyID + '/GeoDatabaseIngest', Obj, {
      headers,
      observe: 'response',
      responseType: 'text',
    });
  }

  public put_applyextdb(Obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    Obj.customerId = this.customerID;
    Obj.agencyId = this.agencyID;
    return this.http.post(this.geobaseURL + 'ingest/applyDataSetExternal', Obj, {
      headers,
      observe: 'response',
      responseType: 'text',
    });
  }
  public getallagencies() {
    return this.http.get(this.baseUrl + '/' + this.customerID + '/agencies?compact=false&offset=0&limit=-1&order=id');
  }
  public post_createagency(Obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseUrl + '/' + this.customerID + '/agencies/' + this.agencyID + '/services', Obj, {
      headers,
      observe: 'response',
    });
  }
  public post_createagencyByName(agency: string, Obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseUrl + '/' + this.customerID + '/agencies/' + agency + '/services', Obj, {
      headers,
      observe: 'response',
    });
  }
  // Update customer schema
  public put_customeryschema(ID: string, Obj) {
    const headers = new HttpHeaders({});
    return this.http.put(this.baseUrl + '/' + this.customerID + '/services/' + ID, Obj, {
      headers,
      observe: 'response',
    });
  }
  public get_filesasurl(filesToScan: string[], serviceName: string) {
    return this.http.post<any>(this.sasTokenURL + '/files/Service/' + serviceName, filesToScan);
  }

  public scanfile(Obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<any>(this.geobaseURL + 'ingest/VirusScan', Obj, { headers, observe: 'response' });
  }

  public checkfilesInBlob(Obj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.geobaseURL + 'ingest/CheckFilesInBlob', Obj, { headers, observe: 'response',
    responseType: 'text' }).pipe(
      retry(5) // retry 5 times before giving error.
    );
  }
  public gettenantId(type: string) {
    return this.http.get(this.geobaseURL + 'TenantId/' + this.customerID + '/GetCustomerTenantId/' + type, {
      responseType: 'text',
    });
  }
  public getCustomerTenantId(customerId: string, type: string) {
    return this.http.get(this.geobaseURL + 'TenantId/' + customerId + '/GetCustomerTenantId/' + type, {
      responseType: 'text',
    });
  }
  public getCustomerList(type: string) {
    return this.http.get(this.geobaseURL + 'TenantId/' + 'GetCustomerIDs/' + type);
  }
  public getCustomerListByTenant(type: string, tenantId: string) {
    return this.http.get(this.geobaseURL + 'TenantId/' + tenantId + '/GetCustomerIdsByTenantId/' + type);
  }
  public getVerifyTenantID(tid: string) {
    return this.http.get(this.geobaseURL + 'TenantId/' + tid + '/IsTenantInUse', { responseType: 'text' });
  }
  public post_addcustomertenant(body: object) {
    return this.http.post(this.geobaseURL + 'TenantId/AddCustomerTenantID', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }
  public post_updatecustomertenant(body: object) {
    return this.http.post(this.geobaseURL + 'TenantId/UpdateCustomerTenantID', body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }
  public post_deletecustomertenant(type: string) {
    return this.http.delete(this.geobaseURL + 'TenantId/' + this.customerID + '/DeleteCustomerTenantID/' + type, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }
  public CustomerOnBoarding(TenantId: string, Size: string) {
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<any>(this.geobaseURL + 'Boundary/customeronboarding/' + TenantId + '/' + Size, opt);
  }
  public updateTenantSize(updatetenantSize, customerId) {
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put(this.geobaseURL + 'Boundary/tenant/' + customerId, updatetenantSize, opt);
  }

  public getTenantSize(customerId) {
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(this.geobaseURL + 'Boundary/tenant/' + customerId, opt);
  }

  public post_tenant_phz(customerId, tenantSize, phzEditEnabled) {
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(this.geobaseURL + 'Phz/customeronboarding/' + customerId,
    {
      tenantSize,
      phzEditEnabled
    }, opt);
  }

  public getCustomerGeocoderType(customerId) {
    return this.http.get(this.geobaseURL + 'Config/' + customerId + '/Geocoder', {
      responseType: 'text',
    });
  }

  public setAgencyGeocoderConfiguration(customerId, agencyId, geocoderName, config) {
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(this.geobaseURL + 'Config/' + customerId + '/' + agencyId + '/GeocoderConfig', config, opt);
  }

  public setRootAgencyGeocoderConfiguration(geoverificationConfigModel) {
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(this.geobaseURL + 'Config/Geocoder', geoverificationConfigModel, opt);
  }

  public getAgencyConfiguration(customerId, agencyId) {
    return this.http.get(this.geobaseURL + 'Config/' + customerId + '/' + agencyId + '/GeocoderConfig', {
      responseType: 'text',
    });
  }


  public getBoundaryConfiguration(customerId, agencyId) {
    return this.http.get<any>(this.geobaseURL + 'Config/' + customerId + '/' + agencyId + '/BoundaryConfig', {
    });
  }

  public getGeocoderDefaultConfiguration(geocoderName) {
    return this.http.get(this.geobaseURL + 'Config/' + geocoderName + '/DefaultConfig', {
      responseType: 'text',
    });
  }

  public get_ActiveDB(customerId) {
    return this.http.get(this.geobaseURL + 'Config/' + customerId + '/GetCustomerActiveDB', {
      responseType: 'text',
    });
  }

  public GetCCAdminCustomers(serviceName: string) {
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(this.geobaseURL + 'CCAdmin/GetAllServicesCustomers/' + serviceName, opt);
  }
  public setTenantActiveDB(tenantId, currentActiveDB, GeocoderType) {
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put(
      this.geobaseURL + 'Config/' + tenantId + '/' + GeocoderType + '/ActiveDB/' + currentActiveDB,
      opt,
      {
        responseType: 'text',
      }
    );
  }

  public OnBoardCustomer(cid: string, onboardTenantModel: object) {
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'text',
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.geobaseURL + 'Tenant/OnBoarding/' + cid, onboardTenantModel, {
      headers,
      observe: 'response',
      responseType: 'text',
    });
  }

  public OnBoardCustomerChild(cid: string, onboardTenantModel: object) {
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'text',
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.geobaseURL + 'Tenant/OnBoarding/' + cid + '/' + cid, onboardTenantModel, {
      headers,
      observe: 'response',
      responseType: 'text',
    });
  }

  public TestGeocoderConfiguration(customerId, agencyId, config) {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    return this.http.post(this.geobaseURL + 'Config/' + customerId + '/' + agencyId + '/TestCustomerConfiguration', config, {
      headers,
      observe: 'response',
      responseType: 'text',
    });
  }
}
