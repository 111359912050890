import { Component, OnInit } from '@angular/core';
import { RestService } from '../services/rest.service';
import { ToastService } from '@msi/cobalt';
import { HttpErrorResponse } from '@angular/common/http';
import {CcadminHelperService } from '../services/ccadmin-helper.service';

@Component({
  selector: 'app-edit-external-tenant',
  templateUrl: './edit-external-tenant.component.html'
})
export class EditExternalTenantComponent implements OnInit {

  constructor(
    public rest: RestService,
    private toastService: ToastService,
    private ccAdmin: CcadminHelperService
  ) { }
    TenantId;
    isNewTenantId = true;
    CanSetTenantId = true;
  ngOnInit() {
    this.rest.getMe().subscribe(data => {
      this.ccAdmin.CCAdminIsImpersonating(data);
      this.CanSetTenantId = this.ccAdmin.IsSuperAdmin;
      if (!this.CanSetTenantId) {
          this.toastService.error(`You do not have valid permissions to edit Tenant ID. You must be an MSI Super-Admin.`);
      }
      this.rest.agencyID = data._links.agency.agencyId;
      this.rest.customerID = data._links.customer.customerId;


      this.rest.gettenantId('External').subscribe(x => {
        if (x === '') {
          this.toastService.warning(`Customer does not have a stored TenantId, see suggested TenantId`);
          this.TenantId = this.rest.customerID + '-external';
          const re = /\./gi;
          this.TenantId = this.TenantId.toLowerCase().replace(re, '-');

        }
        else {
        this.TenantId = x;
        this.isNewTenantId = false;
        }
      },
      (e: HttpErrorResponse) => {
        if (e.status === 404) {
          this.toastService.warning(`Customer does not have a stored TenantId, see suggested TenantId`);
          this.TenantId = this.rest.customerID + '-external';
          const re = /\./gi;
          this.TenantId = this.TenantId.toLowerCase().replace(re, '-');
        } else {
        this.toastService.error(`Failed to get Customer TenantId.`);
        }
      });
    });
  }

  SetTenantId() {
    const tenantId = ((document.getElementById('TenantIdInput') as HTMLInputElement).value);
    if (tenantId.match(/^[0-9a-z](?!.*-{2})[0-9a-z-]{1,61}[0-9a-z]$/)) {
    if (this.isNewTenantId === true ) {
      const body = {
        CustomerId: this.rest.customerID,
        isExistingTenant: false,
        TenantId: tenantId,
        serviceName: 'External'

      };
      this.rest.post_addcustomertenant(body).subscribe(x => {
        this.toastService.success(`Customer TenantId set as ${tenantId}.`);
      },
      (e: HttpErrorResponse) => {
        if (e.error === 'TenantId Already exists') {
          this.toastService.warning('tenantId matches one of another customer');

        } else {
          this.toastService.error(`Failed to update Customer TenantId to ${tenantId} due to error: ${e.error}`);

        }      }
      );
    } else {
      const body = {
        CustomerId: this.rest.customerID,
        isExistingTenant: false,
        TenantId: tenantId,
        serviceName: 'External'
      };
      this.rest.post_updatecustomertenant(body).subscribe(x => {
        this.toastService.success(`Customer TenantId updated to ${tenantId}.`);

      },
      (e: HttpErrorResponse) => {
        if (e.error === 'TenantId Already exists') {
          this.toastService.warning('tenantId matches one of another customer');

        } else {
          this.toastService.error(`Failed to update Customer TenantId to ${tenantId} due to error: ${e.error}`);

        }
      }
      );
    }
  } else {
    let tenantIdtext = 'TenantId must be between 3 & 63 characters, contain only lowercase letters';
    tenantIdtext = tenantIdtext + 'numbers or a - and start and end with a letter or number.';
    this.toastService.error(tenantIdtext);
  }
    }

}
